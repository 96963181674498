/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Content, Description, Label, TextWrapper } from './styled'

const FormLabel = ({ title, subtitle, width = 30, description }) => {
  const { t } = useTranslation()
  return (
    <Label width={width}>
      <Content>
        <TextWrapper fontWeight="fw_500" fontSize="size_13">{title}</TextWrapper>
        {description && (
        <Description description={description}>
          {description === 'Optional' ? t('common.label_optional') : t('common.label_required')}
        </Description>
        )}
      </Content>
      {subtitle && <TextWrapper fontSize="size_12">{subtitle}</TextWrapper>}
    </Label>
  )
}

export default FormLabel

import styled from 'styled-components'
import { Modal as ModalAntd } from 'antd'

export const ModalWrapper = styled(ModalAntd)`
  .ant-modal-content {
    border-radius: 1rem;
    overflow: ${(theme) => theme.overflow || 'auto'};

    .ant-modal-header {
      padding: 0 0 5px 0;
    }

    .ant-modal-body {
      overflow-x: hidden;
      max-height: 80vh;
    }

    .ant-modal-footer {
      display: flex;
      justify-content: center;
      height: 55px;
      .button__cancel {
        margin-right: 10px;
      }
    }
  }
`
export const TextWrapper = styled.p`
  color: ${({ color, theme }) => theme[color]};
  padding: 0;
  margin: 0;
  font-size: ${({ fontSize, theme }) => theme[fontSize] || '16px'};
  font-weight: ${({ fontWeight, theme }) => theme[fontWeight] || '400'};

  @media screen and (max-width: 1280px) {
    font-size: 14px;
  }
`

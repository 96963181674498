export const LOAD_PROFILE = '@PROFILE/LOAD_PROFILE'
export const LOAD_PROFILE_SUCCESS = '@PROFILE/LOAD_PROFILE_SUCCESS'
export const LOAD_PROFILE_FAILURE = '@PROFILE/LOAD_PROFILE_FAILURE'

export const UPDATE_PROFILE = '@PROFILE/UPDATE_PROFILE'
export const UPDATE_PROFILE_SUCCESS = '@PROFILE/UPDATE_PROFILE_SUCCESS'

export const UPLOAD_AVATAR = '@PROFILE/UPLOAD_AVATAR'
export const UPLOAD_AVATAR_SUCCESS = '@PROFILE/UPLOAD_AVATAR_SUCCESS'

export const CHANGE_PASSWORD = '@PROFILE/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_SUCCESS = '@PROFILE/CHANGE_PASSWORD_SUCCESS'
export const REMOVE_ERROR_PASSWORD = '@PROFILE/REMOVE_ERROR_PASSWORD'

export const VERIFY_CODE = '@PROFILE/VERIFY_CODE'
export const VERIFY_CODE_SUCCESS = '@PROFILE/VERIFY_CODE_SUCCESS'

export const UPDATE_EMAIL = '@PROFILE/UPDATE_EMAIL'
export const UPDATE_EMAIL_SUCCESS = '@PROFILE/UPDATE_EMAIL_SUCCESS'

export const LOAD_REPOS_ERROR = '@PROFILE/LOAD_REPOS_ERROR'

export const RESET_PROFILE = '@PROFILE/RESET_PROFILE'

export const GET_PLAN = '@PROFILE/GET_PLAN'
export const GET_PLAN_SUCCESS = '@PROFILE/GET_PLAN_SUCCESS'
export const GET_PLAN_ERROR = '@PROFILE/GET_PLAN_ERROR'
export const GET_DATA_PLAN = '@PROFILE/GET_DATA_PLAN'
export const GET_DATA_PLAN_SUCCESS = '@PROFILE/GET_DATA_PLAN_SUCCESS'
export const GET_DATA_PLAN_ERROR = '@PROFILE/GET_DATA_PLAN_ERROR'
export const TRIAL_EXPIRED = '@PROFILE/TRIAL_EXPIRED'
export const ERROR_COMPANY_HAS_CANCELLATION = '@PROFILE/ERROR_COMPANY_HAS_CANCELLATION'
export const PAYMENT_EXPIRED = '@PROFILE/PAYMENT_EXPIRED'
export const RESET_TRIAL_EXPIRED = '@PROFILE/RESET_TRIAL_EXPIRED'

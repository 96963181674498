/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Col, Pagination, Row, Spin } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { EMPTYLIST } from '../../../assets'
import { Image, SubmitButton, TextPrimary } from '../../../components'
import { Wrapper } from './styled'
import HomeLayout from '../../layouts/home'
import { Topic, HeaderBlock } from '../components'
import { useDailyReports, useHistories } from '../../../hooks'
import { ROUTES_NAME } from '../../../constants'
import Folders from '../components/Folder'
import ModalCreateFolder from '../components/ModalFolder/Create'

const DailyReports = () => {
  // Use hooks
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistories()
  const { dailyReports, foldersReport,
    loadDailyReportsAction,
    loadDailyReportFolderAction,
    createDailyReportFolderAction,
    moveDailyReportToFolderAction
  } = useDailyReports()
  const { data, pagination, filter, isLoading } = dailyReports
  const [activeIndex, setActiveIndex] = useState()
  const [isShowModalFolder, setIsShowModalFolder] = useState(false)
  const [isLoadingMovingFolder, setIsLoadingMovingFolder] = useState(false)

  // End use hooks

  const dailyReportFolder = useMemo(() => foldersReport?.data?.map((item) => (item.id === 0
    ? { ...item, folderName: t('dailyReports.show_all') }
    : item),
  [foldersReport, pagination, t]))
  const urlSearchParams = new URLSearchParams(search).get('dailyReportFolderId')
  const dailyReportFolderId = useMemo(() => +urlSearchParams, [urlSearchParams])

  const handleOnChangePage = (page, pageSize) => {
    const params = {
      ...filter,
      page,
      limit: pageSize
    }
    if (dailyReportFolderId) {
      params.dailyReportFolderId = +dailyReportFolderId
    }
    loadDailyReportsAction({ data: params })
  }

  useEffect(() => {
    loadDailyReportFolderAction()
  }, [])

  useEffect(() => {
    const params = {
      ...filter,
      page: 1
    }
    if (dailyReportFolderId) {
      params.dailyReportFolderId = dailyReportFolderId
    }

    loadDailyReportsAction({ data: params })
  }, [dailyReportFolderId])

  const handleDragStart = (event, dailyReportId) => {
    event.dataTransfer.setData('text/plain', dailyReportId)
  }

  const handleDragOver = (event, index) => {
    event.stopPropagation()
    event.preventDefault()
    setActiveIndex(index)
  }

  const handleDrop = (event, folderId) => {
    event.preventDefault()

    if (!folderId || folderId === dailyReportFolderId) return

    const params = {
      ...filter
    }

    if (dailyReportFolderId) {
      params.dailyReportFolderId = dailyReportFolderId
    }

    setIsLoadingMovingFolder(true)
    moveDailyReportToFolderAction({
      dailyReportId: +event.dataTransfer.getData('text'),
      dailyReportFolderId: folderId,
      filter: dailyReportFolderId && params,
      callback: () => {
        setActiveIndex(null)
        setIsLoadingMovingFolder(false)
      }
    })
  }

  const handleDragLeave = () => {
    setActiveIndex(null)
  }

  const handleNavigateDetailFolder = (id) => {
    history.push(`${ROUTES_NAME.DAILY_REPORTS}${id !== 0 ? `?dailyReportFolderId=${id}` : ''}`)
  }

  return (
    <HomeLayout>
      <Spin spinning={isLoadingMovingFolder}>
        <Wrapper>
          <Row gutter={[32]}>
            <Col span={24} className="header-block">
              <HeaderBlock
                filter={filter}
                loadDailyReportsAction={loadDailyReportsAction}
                dailyReportsTotal={pagination.total}
                dailyReportFolderId={dailyReportFolderId}
              />
            </Col>
            <Col span={6} style={{ paddingLeft: 0 }}>
              <div className="folders-wrapper">
                <div className="folders">
                  {
                dailyReportFolder.map((item, index) => (
                  <Folders
                    item={item}
                    index={index}
                    dailyReportFolderId={dailyReportFolderId}
                    handleNavigateDetailFolder={handleNavigateDetailFolder}
                    activeIndex={activeIndex}
                    handleDragOver={handleDragOver}
                    handleDragLeave={handleDragLeave}
                    handleDrop={handleDrop}
                  />
                ))
              }
                </div>

                <SubmitButton
                  className="btn__addnew"
                  title={t('talk_board.add_new_folder')}
                  fontWeight="fw_500"
                  fontSize="size_14"
                  onClick={() => setIsShowModalFolder(true)}
                />
              </div>
            </Col>
            <Col span={18} className="reports-wrapper">
              <Spin spinning={isLoading}>
                {pagination.total > 0
                  ? data.map((dailyReport) => (
                    <Topic
                      key={dailyReport.id}
                      dailyReport={dailyReport}
                      onDragStart={handleDragStart}
                      dailyReportFolderId={dailyReportFolderId}
                    />
                  ))
                  : (
                    <Col span={24} className="list-empty">
                      <Image src={EMPTYLIST} />
                      <TextPrimary color="grey" fontSize="size_16">
                        {t('common.no_data')}
                      </TextPrimary>
                    </Col>
                  )}

              </Spin>
              {pagination.total > 0 && (
              <Pagination
                total={pagination.total}
                current={pagination.page}
                pageSize={pagination.limit}
                showSizeChanger
                onChange={handleOnChangePage}
                pageSizeOptions={[20, 50, 100]}
                locale={{ items_per_page: `/ ${t('talk_board.page')}` }}
              />
              )}
            </Col>
          </Row>
        </Wrapper>
      </Spin>
      {isShowModalFolder && (
      <ModalCreateFolder
        isVisible={isShowModalFolder}
        setIsVisible={setIsShowModalFolder}
        onHandleSubmit={createDailyReportFolderAction}
      />
      )}
    </HomeLayout>
  )
}

export default DailyReports

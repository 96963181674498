/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOAD_USER_CATEGORIES = '@COURSE_LIST/LOAD_USER_CATEGORIES'
export const LOAD_USER_CATEGORIES_SUCCESS = '@COURSE_LIST/LOAD_USER_CATEGORIES_SUCCESS'

export const LOAD_COURSE_CATEGORIES = '@COURSE_LIST/LOAD_COURSE_CATEGORIES'
export const LOAD_COURSE_CATEGORIES_SUCCESS = '@COURSE/LOAD_COURSE_CATEGORIES_SUCCESS'

export const LOAD_COURSE_COMPANY = '@COURSE_LIST/LOAD_COURSE_COMPANY'
export const LOAD_COURSE_COMPANY_SUCCESS = '@COURSE_LIST/LOAD_COURSE_COMPANY_SUCCESS'

export const LOAD_COURSE_COST = '@COURSE_LIST/LOAD_COURSE_COST'
export const LOAD_COURSE_COST_SUCCESS = '@COURSE_LIST/LOAD_COURSE_COST_SUCCESS'

export const LOAD_COURSE_FREE = '@COURSE_LIST/LOAD_COURSE_FREE'
export const LOAD_COURSE_FREE_SUCCESS = '@COURSE_LIST/LOAD_COURSE_FREE_SUCCESS'

export const LOAD_COURSE_NISSOKEN = '@COURSE_LIST/LOAD_COURSE_NISSOKEN'
export const LOAD_COURSE_NISSOKEN_SUCCESS = '@COURSE_LIST/LOAD_COURSE_NISSOKEN_SUCCESS'

export const LOAD_COURSE_TYPE = '@COURSE_LIST/LOAD_COURSE_TYPE'
export const LOAD_COURSE_TYPE_SUCCESS = '@COURSE_LIST/LOAD_COURSE_TYPE_SUCCESS'

export const LOAD_USER_ROLE = '@COURSE_LIST/LOAD_USER_ROLE'
export const LOAD_USER_ROLE_SUCCESS = '@COURSE_LIST/LOAD_USER_ROLE_SUCCESS'

export const LOAD_REPOS_ERROR = '@COURSE_LIST/LOAD_REPOS_ERROR'

export const CHANGE_COURSE_TAB = '@COURSE_LIST/CHANGE_COURSE_TAB'

export const ADD_TO_CART = '@COURSE_LIST/ADD_TO_CARD'
export const ADD_TO_CARD_SUCCESS = '@COURSE_LIST/ADD_TO_CARD_SUCCESS'
export const GET_COURSE_IN_CART = '@COURSE_LIST/GET_COURSE_IN_CART'
export const GET_COURSE_IN_CART_SUCCESS = '@COURSE_LIST/GET_COURSE_IN_CART_SUCCESS'
export const REMOVE_FROM_CART = '@COURSE_LIST/REMOVE_FROM_CART'
export const REMOVE_FROM_CART_SUCCESS = '@COURSE_LIST/REMOVE_FROM_CART_SUCCESS'
export const INIT_CART = '@COURSE_LIST/INIT_CART'

export const VOTE_LIKE_COURSE = '@COURSE_LIST/VOTE_LIKE_COURSE'
export const VOTE_LIKE_COURSE_SUCCESS = '@COURSE_LIST/VOTE_LIKE_COURSE_SUCCESS'

export const RESET_FILTER_COURSE_LIST = '@COURSE_LIST/RESET_FILTER_COURSE_LIST'

export const SEARCHING_COURSE_LIST = '@COURSE_LIST/SEARCHING_COURSE_LIST'

export const REFRESH_PAGE = '@COURSE_LIST/REFRESH_PAGE'
export const REQUEST_REFRESH_PAGE = '@COURSE_LIST/REQUEST_REFRESH_PAGE'
export const RESET_PAGE_TO_INIT = '@COURSE_LIST/RESET_PAGE_TO_INIT'

export const LOAD_NEW_COURSE = '@COURSE_LIST/LOAD_NEW_COURSE'
export const LOAD_NEW_COURSE_SUCCESS = '@COURSE_LIST/LOAD_NEW_COURSE_SUCCESS'

export const UPDATE_BOOKMARK = '@COURSE_LIST/UPDATE_BOOKMARK'

/* eslint-disable no-plusplus */
/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 * @param  {state} login state
 * @param  {action} login action
 */
import { LOCATION_CHANGE } from 'connected-react-router'

import { FAILURE, REQUEST, SUCCESS, createReducer, updateObject } from '../../../store'
import { editArray, updateObjectInArray } from '../../../utils/utils'
import {
  LOAD_COURSE_DETAIL,
  LOAD_COURSE_DETAIL_SUCCESS,
  LOAD_LESSON_BY_ID,
  LOAD_LESSON_BY_ID_SUCCESS,
  SUBMIT_LESSON_BY_ID,
  SUBMIT_LESSON_BY_ID_SUCCESS,
  LOAD_COURSE_DETAIL_UNREGISRTERED,
  LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS,
  LOAD_REPOS_ERROR,
  VOTE_LIKE_UNIT_SUCCESS,
  VOTE_LIKE_UNIT,
  COUNT_VIEW_UNIT_SUCCESS,
  UPLOAD_FILE,
  UDPATE_CREATE_LECTURE_FILE,
  RESET_CREATE_LECTURE_FILE,
  CREATE_UNIT_LESSON,
  RESET_CREATE_LESSON,
  CREATE_HISTORY,
  CREATE_HISTORY_SUCCESS,
  UPLOAD_USER_PAGE_FOLDER_ID,
  CHANGE_ORDER_OFFSET,
  GET_DATE_COMPLETE_TIME,
  GET_DATE_COMPLETE_TIME_SUCCESS,
  LOAD_COMMENTS,
  CREATE_UPDATE_COMMENTS,
  CREATE_UPDATE_COMMENTS_SUCCESS,
  SET_COMMENT_FOR_EDIT,
  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  LIKE_COMMENT,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  DISLIKE_COMMENT,
  DISLIKE_COMMENT_SUCCESS,
  DISLIKE_COMMENT_FAILURE,
  RESET_UPLOAD_USER_PAGE_FOLDER_ID
} from './constants'
import { SORT_TYPE } from '../../../constants'

const lessonDetailInitial = {
  completionSettings: null,
  courseId: null,
  estimatedStudyTime: 0,
  images: [],
  lessonId: 0,
  limitEnd: 0,
  limitEndType: null,
  limitStart: 0,
  limitStartType: null,
  messageCompleted: null,
  path: '',
  type: 0,
  unitDetails: '',
  unitName: ''
}

const createLessonInitial = {
  isLoading: false,
  isSuccess: false,
  data: null,
  error: null
}

const couseDetailInitial = {
  listCourseCategoryName: [],
  courseImagePath: '',
  courseName: '',
  numberOfCompletedCourse: 0,
  numberOfTotalCourse: 0,
  overviewText: '',
  progressCourseComplete: 0,
  endTime: 0,
  isNishokenCourse: false,
  overview: '',
  recentStudyTime: 0,
  recentStudyUnitName: '',
  startTime: 0,
  studyTimes: 0,
  tagName: [],
  targetTime: 0
}

const comments = {
  isLoading: false,
  isSubmitting: false,
  isLiking: false,
  isDisliking: false,
  data: [],
  pagination: {
    page: 1,
    limit: 10,
    total: 0
  },
  filter: {
    page: 1,
    limit: 10,
    sortType: SORT_TYPE.DESC,
    textSearch: ''
  },
  commentForEdit: {},
  error: null
}

export const initialState = {
  error: null,
  userId: 1,
  lessonDetail: {
    isLoading: false,
    data: lessonDetailInitial,
    error: null,
    isLoadDataSuccess: false,
    historyId: undefined,
    dateCompleteTime: 0
  },
  uploadUserPageFolderId: {
    data: null,
    error: null,
    isLoading: false
  },
  isSubmittingFile: false,
  isRequestPasswordVideo: false,
  isSubmitEnd: false,
  createLectureFileDetail: {
    url: '',
    filename: '',
    size: 0,
    fileType: ''
  },
  createLesson: { ...createLessonInitial },
  courseDetail: {
    isLoading: false,
    isLiking: false,
    data: {
      ...couseDetailInitial,
      unit: []
    },
    error: null
  },
  isChangeOrderOffset: false,
  courseDetailUnregistered: {
    isLoading: false,
    data: {
      ...couseDetailInitial,
      free: false,
      price: null,
      listUserCourseUnitResponse: []
    },
    error: null
  },
  comments: { ...comments }
}

function loadCourseDetail(state) {
  return updateObject(state, {
    courseDetail: {
      ...state.courseDetail,
      isLoading: true
    }
  })
}

function loadCourseDetailSuccess(state, { data }) {
  return updateObject(state, {
    courseDetail: {
      ...state.courseDetail,
      isLoading: false,
      error: null,
      data: data || state.courseDetail.data
    }
  })
}

function loadCouresDetailUnregistered(state) {
  return updateObject(state, {
    courseDetailUnregistered: {
      ...state.courseDetailUnregistered,
      isLoading: true
    },
    isSubmitEnd: false
  })
}

function loadCouresDetailUnregisteredSuccess(state, { data }) {
  return updateObject(state, {
    courseDetailUnregistered: {
      isLoading: false,
      error: null,
      data: data || state.courseDetailUnregistered.data
    }
  })
}

function loadLessonById(state) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      isLoading: true,
      isLoadDataSuccess: false
    },
    isSubmitEnd: false
  })
}

function loadLessonByIdSuccess(state, { data }) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      isLoading: false,
      error: null,
      data,
      isLoadDataSuccess: true
    }
  })
}

function submitLessonById(state) {
  return updateObject(state, {
    isSubmitEnd: false
  })
}

function submitLessonByIdSuccess(state) {
  return updateObject(state, {
    isSubmitEnd: true
  })
}

function repoLoadingError(state, { error }) {
  return updateObject(state, {
    error
  })
}

function voteLikeUnitRequest(state) {
  return updateObject(state, {
    courseDetail: {
      ...state.courseDetail,
      isLiking: true
    }
  })
}

function voteLikeUnitSuccess(state, { payload }) {
  const { data, item } = payload
  item.isLike = !item.isLike
  item.countLikeCourse = data.likeNumber
  const newData = editArray(state.courseDetail.data.unit, item, (el) => el.unitId === item.unitId)

  const newCourseDetail = state.courseDetail
  newCourseDetail.data.unit = newData
  newCourseDetail.isLiking = false
  return updateObject(state, {
    courseDetail: { ...newCourseDetail }
  })
}

function countViewUnitSuccess(state) {
  return updateObject(state, {
    lessonDetail: { ...state.lessonDetail }
  })
}

function createHistory(state) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      isLoading: true
    }
  })
}

function createHistorySuccess(state, { data }) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      historyId: data,
      isLoading: false,
      error: null
    }
  })
}

function getUserPageFolderIdRequest(state) {
  return updateObject(state, {
    uploadUserPageFolderId: {
      ...initialState.uploadUserPageFolderId,
      isLoading: true
    }
  })
}

function getUserPageFolderIdSuccess(state, { data }) {
  return updateObject(state, {
    uploadUserPageFolderId: {
      ...state.uploadUserPageFolderId,
      data,
      isLoading: false
    }
  })
}

export function resetUserPageFolderId(state) {
  return updateObject(state, {
    uploadUserPageFolderId: {
      ...initialState.uploadUserPageFolderId
    }
  })
}

function uploadFile(state) {
  return updateObject(state, {
    ...state,
    isSubmittingFile: true
  })
}

function uploadFileSuccess(state) {
  return updateObject(state, {
    ...state,
    isSubmittingFile: false
  })
}

function uploadFileFailure(state, { error }) {
  return updateObject(state, {
    ...state,
    isSubmittingFile: false,
    error
  })
}

function updateCreateLectureFile(state, { data }) {
  return updateObject({ ...state, createLectureFileDetail: data })
}

function resetCreateLectureFile(state) {
  return updateObject({
    ...state,
    createLectureFileDetail: {
      url: '',
      filename: '',
      size: 0,
      fileType: ''
    }
  })
}

function createLesson(state) {
  return updateObject({
    ...state,
    createLesson: {
      ...state.createLesson,
      isLoading: true
    }
  })
}

function getDateCompleteTime(state) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      isLoading: true
    }
  })
}

function createLessonSuccess(state, { data }) {
  return updateObject({
    ...state,
    createLesson: {
      error: '',
      isLoading: false,
      isSuccess: true
    },
    unitId: data
  })
}

function createLessonError(state, { error }) {
  return updateObject({
    ...state,
    createLesson: {
      ...state.createLesson,
      isLoading: false,
      error
    }

  })
}

function resetCreateLesson(state) {
  return updateObject({
    ...state,
    createLesson: { ...createLessonInitial }
  })
}

function changeOrderOffsetRequest(state) {
  return updateObject({
    ...state,
    courseDetail: {
      ...state.courseDetail,
      isLoading: true
    }
  })
}

function getDateCompleteTimeSuccess(state, { data }) {
  return updateObject(state, {
    lessonDetail: {
      ...state.lessonDetail,
      dateCompleteTime: data,
      isLoading: false,
      error: null
    }
  })
}

function changeOrderOffsetSuccess(state, { payload }) {
  const { source, destination, data } = payload
  const { unit } = state.courseDetail.data

  const newItems = Array.from(unit)
  const [removed] = newItems.splice(source, 1)
  newItems.splice(destination, 0, removed)
  newItems[destination].offset = data

  if (source < destination) {
    for (let i = 0; i < unit.length; i++) {
      if (i >= source && i < destination) {
        newItems[i].offset -= 1
      }
    }
  } else {
    for (let i = 0; i < unit.length; i++) {
      if (i > destination && i <= source) {
        newItems[i].offset += 1
      }
    }
  }

  return updateObject({
    ...state,
    courseDetail: {
      ...state.courseDetail,
      isLoading: false,
      data: {
        ...state.courseDetail.data,
        unit: [...newItems]
      }
    },
    isChangeOrderOffset: true
  })
}

function loadCommentsRequest(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLoading: true
    }
  })
}

function loadCommentsSuccess(state, { data, pagination, filter }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLoading: false,
      data,
      pagination,
      filter
    }
  })
}

function createCommentRequest(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isSubmitting: true
    }
  })
}

function createCommentSuccess(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isSubmitting: false
    }
  })
}

function setCommentForEditRequest(state, { data }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      commentForEdit: data
    }
  })
}

function deleteCommentRequest(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isDeleting: true
    }
  })
}

function deleteCommentSuccess(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isDeleting: false
    }
  })
}

function deleteCommentFailure(state, { error }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isSubmitting: false,
      error
    }
  })
}

function likeCommentRequest(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLiking: true
    }
  })
}

function likeCommentSuccess(state, { data }) {
  data = {
    ...data,
    isLike: data.isLike,
    isDislike: data.isDislike
  }
  const newData = updateObjectInArray(state.comments.data, data, (el) => el.id === data.id)

  let newCommentsData = { ...state.comments.data }
  newCommentsData = newData

  return updateObject(state, {
    comments: {
      ...state.comments,
      isLiking: false,
      data: newCommentsData
    }
  })
}

function likeCommentFailure(state, { error }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isLiking: false,
      error
    }
  })
}

function dislikeCommentRequest(state) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isDisliking: true
    }
  })
}

function dislikeCommentSuccess(state, { data }) {
  data = {
    ...data,
    isLike: data.isLike,
    isDislike: data.isDislike
  }

  const newData = updateObjectInArray(state.comments.data, data, (el) => el.id === data.id)

  let newCommentsData = { ...state.comments.data }
  newCommentsData = newData

  return updateObject(state, {
    comments: {
      ...state.comments,
      isDisliking: false,
      data: newCommentsData
    }
  })
}

function dislikeCommentFailure(state, { error }) {
  return updateObject(state, {
    comments: {
      ...state.comments,
      isDisliking: false,
      error
    }
  })
}

function resetState(state) {
  return updateObject(state, {
    ...initialState,
    courseDetail: {
      ...state.courseDetail,
      data: null
    },
    courseDetailUnregistered: {
      ...state.courseDetailUnregistered,
      data: null
    },
    isSubmitEnd: false
  })
}

export default createReducer(initialState, {
  [LOAD_COURSE_DETAIL]: loadCourseDetail,
  [LOAD_COURSE_DETAIL_SUCCESS]: loadCourseDetailSuccess,

  [LOAD_LESSON_BY_ID]: loadLessonById,
  [LOAD_LESSON_BY_ID_SUCCESS]: loadLessonByIdSuccess,

  [SUBMIT_LESSON_BY_ID]: submitLessonById,
  [SUBMIT_LESSON_BY_ID_SUCCESS]: submitLessonByIdSuccess,

  [LOAD_COURSE_DETAIL_UNREGISRTERED]: loadCouresDetailUnregistered,
  [LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS]: loadCouresDetailUnregisteredSuccess,

  [VOTE_LIKE_UNIT]: voteLikeUnitRequest,
  [VOTE_LIKE_UNIT_SUCCESS]: voteLikeUnitSuccess,

  [COUNT_VIEW_UNIT_SUCCESS]: countViewUnitSuccess,

  [LOAD_REPOS_ERROR]: repoLoadingError,

  [CREATE_HISTORY]: createHistory,
  [CREATE_HISTORY_SUCCESS]: createHistorySuccess,
  [GET_DATE_COMPLETE_TIME]: getDateCompleteTime,
  [GET_DATE_COMPLETE_TIME_SUCCESS]: getDateCompleteTimeSuccess,

  [REQUEST(UPLOAD_USER_PAGE_FOLDER_ID)]: getUserPageFolderIdRequest,
  [SUCCESS(UPLOAD_USER_PAGE_FOLDER_ID)]: getUserPageFolderIdSuccess,
  [RESET_UPLOAD_USER_PAGE_FOLDER_ID]: resetUserPageFolderId,

  [REQUEST(UPLOAD_FILE)]: uploadFile,
  [SUCCESS(UPLOAD_FILE)]: uploadFileSuccess,
  [FAILURE(UPLOAD_FILE)]: uploadFileFailure,

  [UDPATE_CREATE_LECTURE_FILE]: updateCreateLectureFile,
  [RESET_CREATE_LECTURE_FILE]: resetCreateLectureFile,

  [REQUEST(CREATE_UNIT_LESSON)]: createLesson,
  [SUCCESS(CREATE_UNIT_LESSON)]: createLessonSuccess,
  [FAILURE(CREATE_UNIT_LESSON)]: createLessonError,
  [RESET_CREATE_LESSON]: resetCreateLesson,

  [REQUEST(CHANGE_ORDER_OFFSET)]: changeOrderOffsetRequest,
  [SUCCESS(CHANGE_ORDER_OFFSET)]: changeOrderOffsetSuccess,

  [REQUEST(LOAD_COMMENTS)]: loadCommentsRequest,
  [SUCCESS(LOAD_COMMENTS)]: loadCommentsSuccess,

  [CREATE_UPDATE_COMMENTS]: createCommentRequest,
  [CREATE_UPDATE_COMMENTS_SUCCESS]: createCommentSuccess,

  [SET_COMMENT_FOR_EDIT]: setCommentForEditRequest,

  [DELETE_COMMENT]: deleteCommentRequest,
  [DELETE_COMMENT_SUCCESS]: deleteCommentSuccess,
  [DELETE_COMMENT_FAILURE]: deleteCommentFailure,

  [LIKE_COMMENT]: likeCommentRequest,
  [LIKE_COMMENT_SUCCESS]: likeCommentSuccess,
  [LIKE_COMMENT_FAILURE]: likeCommentFailure,

  [DISLIKE_COMMENT]: dislikeCommentRequest,
  [DISLIKE_COMMENT_SUCCESS]: dislikeCommentSuccess,
  [DISLIKE_COMMENT_FAILURE]: dislikeCommentFailure,

  [LOCATION_CHANGE]: resetState
})

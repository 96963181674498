import { parseParamsToQueryString } from '../utils'
import AxiosClient from './api'
import END_POINT from './constants'

function getS3PresignedUrl({ fileList }) {
  const axiosClient = new AxiosClient()
  const url = `${END_POINT.PRE_SIGN}`
  return axiosClient.post(url, fileList)
    .then(({ data }) => data)
}

function getUploadIdFromS3Api({ bucketName, keyName, parts }) {
  const q = parseParamsToQueryString({ bucketName, keyName, parts })
  const axiosClient = new AxiosClient()
  return axiosClient.post(`${END_POINT.GET_UPLOAD_ID_FROM_S3}?${q}`)
    .then(({ data }) => data)
}

function completeUpLoadFileApi({ data }) {
  const axiosClient = new AxiosClient()
  return axiosClient.post(`${END_POINT.COMPLETE_UPLOAD_FILE}`, data)
    .then((res) => res.data)
}

function checkExistFile({ folderId = 0, fileName }) {
  const axiosClient = new AxiosClient()
  return axiosClient.get(`${END_POINT.UPLOAD_FILE.CHECK_EXIST_FILE}/${folderId}?filename=${fileName}`)
    .then(({ data }) => data)
}

function getFolderIdUpload() {
  const axiosClient = new AxiosClient()
  return axiosClient.get(END_POINT.UPLOAD_FILE.USER_PAGE_FOLDER_ID)
    .then(({ data }) => data)
}

function uploadFileAPI({ folderId = 0, params }) {
  const axiosClient = new AxiosClient()
  const q = parseParamsToQueryString(params)
  return axiosClient.post(`${END_POINT.UPLOAD_FILE.UPLOAD_FILE}/${folderId}?${q}`)
    .then((res) => res.data).catch((err) => err)
}

function createUnitLessonApi({ courseId, payload, langCode }) {
  const q = parseParamsToQueryString({ langCode })
  const axiosClient = new AxiosClient()
  return axiosClient.post(`${END_POINT.UPLOAD_FILE.CREATE_LECTURE}/${courseId}?${q}`, payload).then(
    (res) => res.data
  )
}

function changeOrderOffsetApi({ courseId, offsetBefore, offsetAfter, unitId, typeUnit }) {
  const q = new URLSearchParams({ offsetBefore, offsetAfter, unitId, typeUnit })
  const axiosClient = new AxiosClient()
  return axiosClient.put(`${END_POINT.CHANGE_ORDER_OFFSET}/${courseId}?${q}`).then((res) => res.data)
}

function checkStatusFileUpload(id) {
  const axiosClient = new AxiosClient()
  return axiosClient.get(END_POINT.UPLOAD_FILE.CHECK_UPLOAD_FILE_STATUS(id)).then((res) => res.data)
}

export {
  getS3PresignedUrl,
  getUploadIdFromS3Api,
  completeUpLoadFileApi,
  checkExistFile,
  getFolderIdUpload,
  uploadFileAPI,
  createUnitLessonApi,
  changeOrderOffsetApi,
  checkStatusFileUpload
}

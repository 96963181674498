/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import { Card, List, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getLessonById, submitLessonById } from '../../../../apis'
import { countViewUnit, createHistoryUnitApi } from '../../../../apis/course.api'
import {
  EXTERNAL,
  EXTERNAL_DISABLE,
  ICON_YOUTUBE,
  ICON_YOUTUBE_DISABLED,
  LESSON, LESSON_DISABLE,
  NOTIFY_BELL, NOT_FOUND_IMAGE, PDF, PDF_DISABLE, PPT, PPT_DISABLE, QUESTION_ICON, REPORT, REPORT_DISABLE, SURVEY, SURVEY_DISABLE, TEST, TEST_DISABLE, WHITE_ICON_UPLOAD
} from '../../../../assets'
import { ClickAble, Image, TextPrimary, VoteLike, Watched } from '../../../../components'
import CustomImage from '../../../../components/image'
import { LESSON_STATUS_COMPLETE, LESSON_TYPE, TYPE_PATH_LESSON } from '../../../../constants'
import { useCourseDetail, useHistories, useProfile } from '../../../../hooks'
import useQuery from '../../../../hooks/useQuery'
import { dateFormat, SLASH_DATE_FORMAT } from '../../../../utils'
import { FULL_DATE_TIME } from '../../../../utils/date'
import { renderStatus } from '../lesson-layout/components/box-status'
import UnitOverview from './components/unit-overview'
import { BtnUpload, LessonWrapper, SearchEmpty, ToolTipLessonIconWrapper, Wrapper } from './styled'
import CommentForm from '../commentForm'
import Comment from '../../../../components/comment'

const LessonList = ({ lessons, isFree = true, isEnableEditCourseDetail = false, handleShowModalUpload }) => {
  const stylesIcon = {
    fontSize: 24,
    width: 32,
    height: 32,
    marginRight: 12
  }
  const { courseId } = useParams()
  const history = useHistories()
  const { t } = useTranslation()
  const query = useQuery()
  const fromTab = query.get('fromTab')
  const { profile } = useProfile()

  const { voteLikeUnitAction, isLiking, loadCouresDetail, changeOrderOffsetAction } = useCourseDetail({
    userId: profile.userId,
    courseId
  })
  const renderIcon = (icon, type, typePath, isactive = true) => {
    if (icon !== null) {
      switch (icon) {
        case LESSON_TYPE.REPORT:
          return isactive ? <REPORT style={stylesIcon} /> : <REPORT_DISABLE style={stylesIcon} />
        case LESSON_TYPE.SURVEY:
          return isactive ? <SURVEY style={stylesIcon} /> : <SURVEY_DISABLE style={stylesIcon} />
        case LESSON_TYPE.TEST:
          return isactive ? <TEST style={stylesIcon} /> : <TEST_DISABLE style={stylesIcon} />
        case LESSON_TYPE.PPT:
          return isactive ? <PPT style={stylesIcon} /> : <PPT_DISABLE style={stylesIcon} />
        case LESSON_TYPE.PDF:
          return isactive ? <PDF style={stylesIcon} /> : <PDF_DISABLE style={stylesIcon} />
        case LESSON_TYPE.VIDEO:
          if (typePath === 'DEFAULT') {
            return isactive ? <LESSON style={stylesIcon} /> : <LESSON_DISABLE style={stylesIcon} />
          } if (typePath === 'EXTERNAL') {
            return isactive ? (
              <Image
                src={EXTERNAL}
                style={{ with: 31, height: 31, marginRight: 12 }}
              />
            ) : (
              <Image
                src={EXTERNAL_DISABLE}
                style={{ with: 31, height: 31, marginRight: 12 }}
              />
            )
          }
          return isactive ? <ICON_YOUTUBE style={stylesIcon} /> : <ICON_YOUTUBE_DISABLED style={stylesIcon} />

        default:
          return isactive ? <LESSON style={stylesIcon} /> : <LESSON_DISABLE style={stylesIcon} />
      }
    } else {
      switch (type) {
        case LESSON_TYPE.TEST:
          return isactive ? <TEST style={stylesIcon} /> : <TEST_DISABLE style={stylesIcon} />
        case LESSON_TYPE.REPORT:
          return isactive ? <REPORT style={stylesIcon} /> : <REPORT_DISABLE style={stylesIcon} />
        case LESSON_TYPE.SURVEY:
          return isactive ? <SURVEY style={stylesIcon} /> : <SURVEY_DISABLE style={stylesIcon} />
        case LESSON_TYPE.VIDEO:
          if (typePath === 'DEFAULT') {
            return isactive ? <LESSON style={stylesIcon} /> : <LESSON_DISABLE style={stylesIcon} />
          }
          return isactive ? <ICON_YOUTUBE style={stylesIcon} /> : <ICON_YOUTUBE_DISABLED style={stylesIcon} />
        default:
          return isactive ? <LESSON style={stylesIcon} /> : <LESSON_DISABLE style={stylesIcon} />
      }
    }
  }

  const renderToolTipUnitIcon = () => (
    <ToolTipLessonIconWrapper>
      <Card title={t('course_detail.notify_description')} className="lesson-tooltip-icon" bordered={false}>
        <List
          split={false}
          dataSource={[1]}
          renderItem={() => (
            <>
              <List.Item>
                <LESSON style={stylesIcon} /> {t('course_detail.lesson_type_test')}
              </List.Item>
              <List.Item>
                <TEST style={stylesIcon} /> {t('course_detail.lesson_type_lesson')}
              </List.Item>
              <List.Item>
                <REPORT style={stylesIcon} /> {t('course_detail.lesson_type_survey')}
              </List.Item>
              <List.Item>
                <SURVEY style={stylesIcon} /> {t('course_detail.lesson_type_report')}
              </List.Item>
              <List.Item>
                <PDF style={stylesIcon} /> {t('course_detail.lesson_type_pdf')}
              </List.Item>
              <List.Item>
                <PPT style={stylesIcon} /> {t('course_detail.lesson_type_ppt')}
              </List.Item>
              <List.Item>
                <ICON_YOUTUBE style={stylesIcon} />
                {t('course_detail.lesson_type_youtube')}
              </List.Item>
              <List.Item>
                <Image src={EXTERNAL} style={stylesIcon} />
                {t('course_detail.lesson_type_external')}
              </List.Item>
            </>
          )}
        />
      </Card>
    </ToolTipLessonIconWrapper>
  )

  const renderLimitDate = (lesson) => {
    if (lesson.limitAttendPeriodStart && lesson.limitAttendPeriodEnd) {
      return (
        <TextPrimary className="period_time" fontSize="size_12">
          {t('course_detail.period')}:{' '}
          {dateFormat(lesson.limitAttendPeriodStart, SLASH_DATE_FORMAT)} ～ {dateFormat(lesson.limitAttendPeriodEnd, SLASH_DATE_FORMAT)}
        </TextPrimary>
      )
    }
    if (lesson.limitAttendPeriodStart && !lesson.limitAttendPeriodEnd) {
      return (
        <TextPrimary className="period_time" fontSize="size_12">
          {t('course_detail.period')}:{' '}
          {dateFormat(lesson.limitAttendPeriodStart, SLASH_DATE_FORMAT)} ～
        </TextPrimary>
      )
    }
    if (!lesson.limitAttendPeriodStart && lesson.limitAttendPeriodEnd) {
      return (
        <TextPrimary className="period_time" fontSize="size_12">
          {t('course_detail.period')}:{' '}
          ～ {dateFormat(lesson.limitAttendPeriodEnd, SLASH_DATE_FORMAT)}
        </TextPrimary>
      )
    }
    return null
  }

  const isActiveLesson = (lesson) => {
    const date = moment()
    if (!lesson.limitAttendPeriodStart && !lesson.limitAttendPeriodEnd) return true
    if (lesson.limitAttendPeriodStart && !lesson.limitAttendPeriodEnd && lesson.limitAttendPeriodStart <= moment(date).valueOf()) return true
    return moment(date).isBetween(moment(lesson.limitAttendPeriodStart).toDate(), moment(lesson.limitAttendPeriodEnd).toDate())
  }

  const navigateToUnit = ({ type, unitId }) => {
    if (type === LESSON_TYPE.TEST) {
      return `/examination/${courseId}/${unitId}?fromTab=${fromTab}`
    }
    if (type === LESSON_TYPE.SURVEY) {
      return `/survey/${courseId}/${unitId}?fromTab=${fromTab}`
    }
    if (type === LESSON_TYPE.REPORT) {
      return `/report/${courseId}/${unitId}?fromTab=${fromTab}`
    }
    return `/course/${courseId}/lesson/${unitId}?fromTab=${fromTab}`
  }
  const handleNavigate = async (e, lesson) => {
    e.stopPropagation()
    if (isFree) {
      if (lesson.type === LESSON_TYPE.LESSON && lesson.typePath === TYPE_PATH_LESSON.EXTERNAL) {
        window.open(lesson.path, '_blank')
        await getLessonById({ courseId, lessonId: lesson.unitId })
        const { data: historyId } = await createHistoryUnitApi({ courseId, lessonId: lesson.unitId, typeUnit: LESSON_TYPE.LESSON })
        const countView = await countViewUnit(courseId, lesson.unitId, LESSON_TYPE.LESSON)
        if (countView) {
          await submitLessonById({ courseId, lessonId: lesson.unitId, queryParams: { historyId } })
        }
        loadCouresDetail()
        return
      }
      history.push(
        navigateToUnit({
          type: lesson.type,
          unitId: lesson.unitId,
          typePath: lesson.typePath
        })
      )
    }
  }

  const onDragEnd = (result) => {
    const { index: source } = result?.source || null
    const { index: destination } = result?.destination || null

    if (source == null || destination == null || source === destination) return

    const current = lessons[source]

    if (!current) return
    changeOrderOffsetAction({
      courseId,
      offsetBefore: current.offset,
      offsetAfter: lessons[destination]?.offset,
      unitId: current?.unitId,
      typeUnit: current?.type,
      source,
      destination
    })
  }

  const renderDragLessonLayout = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {
              lessons.map((lesson, index) => (
                <Draggable key={lesson.id} draggableId={lesson.id.toString()} index={index}>
                  {(providedDraggable) => (
                    <ClickAble
                      className="unit"
                      style={{
                        pointerEvents: isActiveLesson(lesson) ? 'initial' : 'none',
                        cursor: !isFree && 'default'
                      }}
                      key={lesson.unitId}
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                    >
                      <LessonWrapper
                        isactive={isActiveLesson(lesson)}
                      >
                        <div className="unit__container">
                          {lesson.statusCourse
                          && (
                          <>
                            <div className="unit__new">{t('course_screen.mark_new')}</div>
                            <div className="unit__draw" />
                          </>
                          )}
                          <div
                            className="unit__container__content"
                          >
                            <div className="unit__container__content__header" onClick={(e) => handleNavigate(e, lesson)}>
                              <div className="unit__container__content__header--left">
                                {renderIcon(lesson.typeLesson, lesson.type, lesson.typePath, isActiveLesson(lesson))}
                              </div>
                              <div className="unit__container__content__header--right">
                                {lesson.complete && renderStatus(lesson, t)}
                                {
                              !!lesson.estimateTime && (
                                <div className="estimatedtime-box">
                                  <TextPrimary color="grey" style={{ marginRight: 4 }}>
                                    {t('course_detail.estimate_time_list')}:
                                  </TextPrimary>
                                  <TextPrimary color={isActiveLesson(lesson) ? 'black' : 'grey'}>
                                    {t('common.time.minute', { minutes: lesson.estimateTime })}
                                  </TextPrimary>
                                </div>
                              )
                            }
                                {
                              !!lesson?.submissionDeadline && (
                                <div className="estimatedtime-box">
                                  <TextPrimary fontSize="size_12" color="grey" style={{ marginRight: 4 }}>
                                    {t('course_detail.submission_deadline')}:
                                  </TextPrimary>
                                  <TextPrimary fontSize="size_12" color={isActiveLesson(lesson) ? 'black' : 'grey'}>
                                    {moment.unix(lesson?.submissionDeadline / 1000).format(FULL_DATE_TIME)}
                                  </TextPrimary>
                                </div>
                              )
                            }
                                {renderLimitDate(lesson)}
                              </div>
                            </div>
                            <div className="unit__container__content__name">
                              <div onClick={(e) => handleNavigate(e, lesson)} style={{ flex: 1 }}>
                                <TextPrimary fontSize="size_17" fontWeight="fw_700">{lesson.unitName}</TextPrimary>
                              </div>
                              <div className="vote-container">
                                <Watched
                                  voteNumber={lesson?.countOpenLesson}
                                />
                                <VoteLike
                                  isLike={lesson?.isLike}
                                  courseId={courseId}
                                  courseUnitId={lesson?.unitId}
                                  typeUnit={lesson?.type}
                                  voteAction={voteLikeUnitAction}
                                  voteNumber={lesson?.countLikeCourse}
                                  item={lesson}
                                  isLiking={isLiking}
                                />
                                {lesson?.type === LESSON_TYPE.LESSON && lesson?.typePath !== TYPE_PATH_LESSON.EXTERNAL ? (
                                  <Comment
                                    countComment={lesson.countComments}
                                    textCss="text-card"
                                  />
                                ) : null}
                              </div>
                            </div>
                            <UnitOverview overview={lesson.detail} />
                            {
                          lesson.messageCompleteText && (
                            <div className="notify-box">
                              <Image src={NOTIFY_BELL} />
                              <TextPrimary fontSize="size_14">{lesson.messageCompleteText}</TextPrimary>
                            </div>
                          )
                        }
                          </div>
                        </div>
                        {
                        (lesson?.statusReview === LESSON_STATUS_COMPLETE.EVALUATION_COMPLETED || lesson?.statusReview === LESSON_STATUS_COMPLETE.RESUBMITTED) && (
                          <TextPrimary fontSize="size_12" color="text_danger" className="evaluate">
                            {t('course_detail.evaluate')}
                          </TextPrimary>
                        )
                      }
                      </LessonWrapper>
                    </ClickAble>
                  )}
                </Draggable>
              ))
              }
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

  const renderDefaultLessonLayout = () => (
    lessons.map((lesson) => (
      <ClickAble
        className="unit"
        key={lesson.id}
        style={{
          pointerEvents: isActiveLesson(lesson) ? 'initial' : 'none',
          cursor: !isFree && 'default'
        }}
      >
        <LessonWrapper key={lesson.unitId} isactive={isActiveLesson(lesson)}>
          <div className="unit__container">
            {lesson.statusCourse
              && (
              <>
                <div className="unit__new">{t('course_screen.mark_new')}</div>
                <div className="unit__draw" />
              </>
              )}
            <div
              className="unit__container__content"
            >
              <div className="unit__container__content__header" onClick={(e) => handleNavigate(e, lesson)}>
                <div className="unit__container__content__header--left">
                  {renderIcon(lesson.typeLesson, lesson.type, lesson.typePath, isActiveLesson(lesson))}
                </div>
                <div className="unit__container__content__header--right">
                  {lesson.complete && renderStatus(lesson, t)}
                  {
                  !!lesson.estimateTime && (
                    <div className="estimatedtime-box">
                      <TextPrimary color="grey" style={{ marginRight: 4 }}>
                        {t('course_detail.estimate_time_list')}:
                      </TextPrimary>
                      <TextPrimary color={isActiveLesson(lesson) ? 'black' : 'grey'}>
                        {t('common.time.minute', { minutes: lesson.estimateTime })}
                      </TextPrimary>
                    </div>
                  )
                }
                  {
                  !!lesson?.submissionDeadline && (
                    <div className="estimatedtime-box">
                      <TextPrimary color="grey" style={{ marginRight: 4 }}>
                        {t('course_detail.submission_deadline')}:
                      </TextPrimary>
                      <TextPrimary color={isActiveLesson(lesson) ? 'black' : 'grey'}>
                        {moment.unix(lesson?.submissionDeadline / 1000).format(FULL_DATE_TIME)}
                      </TextPrimary>
                    </div>
                  )
                }
                  {renderLimitDate(lesson)}
                </div>
              </div>
              <div className="unit__container__content__name">
                <div onClick={(e) => handleNavigate(e, lesson)} style={{ flex: 1 }}>
                  <TextPrimary fontSize="size_17" fontWeight="fw_700">{lesson.unitName}</TextPrimary>
                </div>
                <div className="vote-container">
                  <Watched
                    voteNumber={lesson?.countOpenLesson}
                  />
                  <VoteLike
                    isLike={lesson?.isLike}
                    courseId={courseId}
                    courseUnitId={lesson?.unitId}
                    typeUnit={lesson?.type}
                    voteAction={voteLikeUnitAction}
                    voteNumber={lesson?.countLikeCourse}
                    item={lesson}
                    isLiking={isLiking}
                  />
                  {lesson?.type === LESSON_TYPE.LESSON && lesson?.typePath !== TYPE_PATH_LESSON.EXTERNAL ? (
                    <Comment
                      countComment={lesson.countComments}
                      textCss="text-card"
                    />
                  ) : null}
                </div>
              </div>
              <UnitOverview overview={lesson.detail} />
              {
              lesson.messageCompleteText && (
                <div className="notify-box">
                  <Image src={NOTIFY_BELL} />
                  <TextPrimary fontSize="size_14">{lesson.messageCompleteText}</TextPrimary>
                </div>
              )
            }
            </div>
          </div>
          {
            (lesson?.statusReview === LESSON_STATUS_COMPLETE.EVALUATION_COMPLETED || lesson?.statusReview === LESSON_STATUS_COMPLETE.RESUBMITTED) && (
              <TextPrimary fontSize="size_12" color="text_danger" className="evaluate">
                {t('course_detail.evaluate')}
              </TextPrimary>
            )
          }
        </LessonWrapper>
      </ClickAble>
    ))
  )

  return (
    <Wrapper>
      <div className="lesson-header">
        <div className="lesson-title">
          <TextPrimary fontWeight="fw_600" fontSize="size_20">{t('course_detail.list_lesson')}</TextPrimary>
          <Tooltip placement="bottom" title={renderToolTipUnitIcon()} color="white">
            <Image src={QUESTION_ICON} />
          </Tooltip>
        </div>
        {isEnableEditCourseDetail && (
        <BtnUpload onClick={handleShowModalUpload}>
          <img src={WHITE_ICON_UPLOAD} alt="" />
          {t('course_detail.upload_button')}
        </BtnUpload>
        )}
      </div>
      {isEnableEditCourseDetail ? renderDragLessonLayout() : renderDefaultLessonLayout()}
      {!lessons.length && (
        <SearchEmpty>
          <CustomImage src={NOT_FOUND_IMAGE} />
          <TextPrimary color="grey">{t('course_screen.empty_lesson')}</TextPrimary>
        </SearchEmpty>
      )}
      <CommentForm type="PDF" isList courseId={Number(courseId)} />
    </Wrapper>
  )
}
export default LessonList

import styled from 'styled-components'

export const LanguageBox = styled.div`
  position: relative;
  .clickable {
    display: flex;
    align-items: center;
    width: max-content;
  }
`

export const LOAD_LIST_UNIT_QUESTION = '@EXAMINATION/LOAD_LIST_UNIT_QUESTION'
export const LOAD_LIST_UNIT_QUESTION_SUCCESS = '@EXAMINATION/LOAD_LIST_UNIT_QUESTION_SUCCESS'
export const LOAD_LIST_UNIT_QUESTION_ERROR = '@EXAMINATION/LOAD_LIST_UNIT_QUESTION_ERROR'

export const LOAD_TEST_RESULT = '@EXAMINATION/LOAD_TEST_RESULT'
export const LOAD_TEST_RESULT_SUCCESS = '@EXAMINATION/LOAD_TEST_RESULT_SUCCESS'
export const LOAD_TEST_RESULT_ERROR = '@EXAMINATION/LOAD_TEST_RESULT_ERROR'

export const UPDATE_ANSWER_TESTING = '@EXAMINATION/UPDATE_ANSWER_TESTING'

export const SUBMIT_TEST = '@EXAMINATION/SUBMIT_TEST'
export const SUBMIT_TEST_SUCCESS = '@EXAMINATION/SUBMIT_TEST_SUCCESS'
export const SUBMIT_TEST_ERROR = '@EXAMINATION/SUBMIT_TEST_ERROR'

export const LOAD_REPOS_ERROR = '@EXAMINATION/LOAD_REPOS_ERROR'

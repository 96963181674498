/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Divider, Row, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import FolderSchema from './schema'
import { FormInput, ModalReport, OutlineButton, PrimaryButton } from '../../../../../components'
import { CustomFooter } from '../../../../course/components/report-lesson-modal/styled'

export const defaultValues = {
  folderName: ''
}

const ModalCreateFolder = ({ isVisible, setIsVisible, onHandleSubmit, isUpdate = false, folderName, id }) => {
  const { t } = useTranslation()
  const form = useForm({
    resolver: yupResolver(FolderSchema(t)),
    defaultValues,
    mode: 'onChange'
  })
  const { handleSubmit, reset, clearErrors, setValue } = form

  const [isLoadingCreate, setIsLoadingCreate] = useState(false)

  const handleClosePopup = () => {
    if (!isUpdate) {
      reset(defaultValues)
      clearErrors()
    }
    setIsVisible(false)
  }

  const onSubmit = (formData) => {
    setIsLoadingCreate(true)
    if (isUpdate) {
      onHandleSubmit({
        data: {
          id,
          folderName: formData.folderName
        },
        callback: () => {
          setIsVisible(false)
          setIsLoadingCreate(false)
        }
      })
      return
    }
    onHandleSubmit({ formData,
      callback: () => {
        setIsVisible(false)
        setIsLoadingCreate(false)
      }
    })
  }

  useEffect(() => {
    if (isUpdate) {
      clearErrors()
      setValue('folderName', folderName)
    }
  }, [isVisible])

  return (
    <FormProvider {...form}>
      <ModalReport
        icon={isUpdate && EditOutlined}
        isModalVisible={isVisible}
        setIsModalVisible={setIsVisible}
        width={400}
        description={isUpdate ? t('dailyReports.rename') : t('dailyReports.group_name')}
        descriptionSize="size_20"
        fontWeight="fw_700"
        onCancel={handleClosePopup}
        isFooter={false}
        closable
      >
        <Spin spinning={isLoadingCreate}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Divider />
            <Row>
              <FormInput
                t={t}
                placeholder={t('dailyReports.reporting_date')}
                name="folderName"
              />
            </Row>

            <Divider />

            <CustomFooter>
              <PrimaryButton
                title={isUpdate ? t('dailyReports.update') : t('dailyReports.template.create')}
                key="ok"
                htmlType="submit"
                borderRadius={8}
              />
              <OutlineButton
                title={t('create_lecture.cancel_register_button')}
                key="cancel"
                borderRadius={8}
                onClick={handleClosePopup}
              />
            </CustomFooter>
          </form>
        </Spin>
      </ModalReport>
    </FormProvider>
  )
}

export default ModalCreateFolder

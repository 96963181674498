import * as yup from 'yup'

export default (t) => yup.object().shape({
  folderName: yup.string().trim().required(
    t('create_lecture.validate.required', {
      key: t('dailyReports.group_name')
    })
  ).max(
    255,
    t('create_lecture.validate.max_length', {
      key: t('dailyReports.group_name'),
      max: 255
    })
  )
})

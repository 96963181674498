/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Spin, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { FolderOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { ModalReport, OutlineButton, PrimaryButton, TextNormal } from '../../../../../components'
import { CustomFooter } from '../../../../course/components/report-lesson-modal/styled'
import { useDailyReports } from '../../../../../hooks'
import { MoveFolderWrapper } from './styled'

const ModalListFolder = ({ isModalVisible, setIsModalVisible, dailyReportId }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const { dailyReports, foldersReport, loadDailyReportFolderAction, moveDailyReportToFolderAction } = useDailyReports()
  const { filter } = dailyReports
  const [selectFolderId, setSelectFolderId] = useState(null)
  const [isLoadingMoving, setIsLoadingMoving] = useState(false)
  const urlSearchParams = new URLSearchParams(search).get('dailyReportFolderId')
  const dailyReportFolderId = useMemo(() => +urlSearchParams, [urlSearchParams])

  useEffect(() => {
    loadDailyReportFolderAction()
  }, [isModalVisible])

  const handleMovingFolder = () => {
    if (!selectFolderId) return
    const params = {
      ...filter
    }

    if (dailyReportFolderId) {
      params.dailyReportFolderId = dailyReportFolderId
    }
    setIsLoadingMoving(true)
    moveDailyReportToFolderAction({
      dailyReportId,
      dailyReportFolderId: selectFolderId,
      filter: dailyReportFolderId && params,
      callback: () => {
        setIsLoadingMoving(false)
        setIsModalVisible(false)
      }
    })
  }

  return (
    <ModalReport
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
      description={t('dailyReports.moveFolder')}
      descriptionSize="size_20"
      fontWeight="fw_700"
      isFooter={false}
      closable
      onCancel={() => setIsModalVisible(false)}
    >
      <Spin spinning={isLoadingMoving}>
        {
          foldersReport.data.length > 1 && (
          <>
            <Divider />
            <div className="folder__wrapper">
              {
              foldersReport.data.filter((item) => item.id).map((item, index) => (
                <MoveFolderWrapper
                  key={index}
                  onClick={() => setSelectFolderId(item.id)}
                  className={item.id === selectFolderId ? 'selected__folder' : ''}
                >
                  <FolderOutlined style={{ fontSize: '24px' }} />
                  <Tooltip title={item.folderName}>
                    <p>{item.folderName}</p>
                  </Tooltip>
                </MoveFolderWrapper>
              ))
            }
            </div>
            <Divider />
          </>
          )
        }

        <CustomFooter>
          <PrimaryButton
            title={t('dailyReports.move')}
            key="ok"
            type="submit"
            borderRadius={8}
            style={{ cursor: !selectFolderId ? 'not-allowed' : 'pointer' }}
            onClick={handleMovingFolder}
          />
          <OutlineButton
            title={t('create_lecture.cancel_register_button')}
            key="cancel"
            borderRadius={8}
            onClick={() => setIsModalVisible(false)}
          />
        </CustomFooter>
      </Spin>
    </ModalReport>
  )
}

export default ModalListFolder

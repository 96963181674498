export const fontSize = {
  size_12: '12px',
  size_12_6: '12.6px',
  size_13: '13px',
  size_14: '14px',
  size_15: '15px',
  size_16: '16px',
  size_17: '17px',
  size_18: '18px',
  size_19: '19px',
  size_20: '20px',
  size_22: '22px',
  size_24: '24px',
  size_26: '26px',
  size_28: '28px',
  size_32: '32px',
  size_40: '40px'
}

export const fontWeight = {
  bold: 'bold',
  fw_100: 100,
  fw_200: 200,
  fw_300: 300,
  fw_400: 400,
  fw_500: 500,
  fw_600: 600,
  fw_700: 700,
  fw_800: 800,
  fw_900: 900
}

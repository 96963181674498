import styled from 'styled-components'

export const StyledWrapper = styled.div`
    width: 100%;
    .comment-container {
        margin-bottom: 40px;
        &.comment-container-pdf {
            > * {
                margin-left: ${({ isList }) => (!isList ? '284px' : 'unset')};
                margin-right: ${({ isList }) => (!isList ? '100px' : 'unset')};
            }
        }
        .title {
            margin-bottom: 14px;
            font-size: 16px;
            font-weight: 400;
        }
        .header-comment {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 12px;
            padding-bottom: 12px;
            border-radius: 1px;
            margin-top: 20px;
            box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.15);
            .up {
                transform: rotate(-135deg);
            }
            .down {
                transform: rotate(45deg)
            }
            .arrow {
                border: solid #00C271;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 2px;
                margin-left: 10px;
            }
        }
        .content-comment {
            margin-top: 20px;
            textarea {
                max-height: 72px;
            }
        }
        .comment__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .ant-select {
                &:hover {
                .ant-select-selector {
                    border-color: ${({ theme }) => theme.green};
                }
            }
        }
  }
  }
`

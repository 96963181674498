/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Divider, Row, Spin } from 'antd'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { FormInput, ModalReport, OutlineButton, PrimaryButton } from '../../../../components'
import FormLabel from '../../../../components/form/FormLabel'
import { CustomFooter, Right } from './styled'
import CreateLectureShcema from './schema'
import { FILE_EXTENSION_UPLOAD, LESSON_DETAIL_TYPE, TYPE_PATH_LESSON } from '../../../../constants'
import { STORAGE, getLocalStorage } from '../../../../utils'

export const defaultValues = {
  unitName: ''
}

const ReportLessonModal = ({
  courseId,
  isShowModalReport,
  setIsShowModalReport,
  createFileLectureData,
  createLessonData,
  resetCreateLectureFileData,
  createUnitLessonAction,
  resetCreateLessonAction
}) => {
  const { t } = useTranslation()

  const form = useForm({
    resolver: yupResolver(CreateLectureShcema(t)),
    defaultValues,
    mode: 'onChange'
  })
  const { handleSubmit, reset, clearErrors, setValue } = form

  const handleClosePopup = () => {
    reset(defaultValues)
    setIsShowModalReport(false)
    clearErrors()
    resetCreateLectureFileData()
  }

  const fileTypeUpload = useMemo(() => {
    const fileType = createFileLectureData?.fileType?.toLowerCase()
    if (fileType === FILE_EXTENSION_UPLOAD.PPT || fileType === FILE_EXTENSION_UPLOAD.PPTX) return LESSON_DETAIL_TYPE.PPT
    if (fileType === FILE_EXTENSION_UPLOAD.PDF) return LESSON_DETAIL_TYPE.PDF
    return LESSON_DETAIL_TYPE.VIDEO
  }, [createFileLectureData])

  const onSubmit = (formData) => {
    resetCreateLessonAction()
    const payload = {
      type: fileTypeUpload,
      isLimitStartTrue: 0,
      isLimitEndTrue: 0,
      messageCompleted: '',
      unitDetails: '',
      typePath: TYPE_PATH_LESSON.DEFAULT,
      unitName: formData.unitName,
      path: createFileLectureData?.url,
      limitStart: '',
      limitEnd: ''
    }
    createUnitLessonAction({
      courseId,
      payload,
      langCode: getLocalStorage(STORAGE.LANGUAGE),
      callback: () => {
        handleClosePopup()
      }
    })
  }

  useEffect(() => {
    setValue('unitName', createFileLectureData?.filename)
  }, [])

  return (
    <FormProvider {...form}>
      <ModalReport
        icon={EditOutlined}
        isModalVisible={isShowModalReport}
        setIsModalVisible={setIsShowModalReport}
        description={t('create_lecture.register_lesson')}
        width="60vw"
        descriptionSize="size_20"
        fontWeight="fw_700"
        isFooter={false}
        closable
        onCancel={handleClosePopup}
      >
        <Spin spinning={false}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Divider />
            <Row>
              <FormLabel title={t('create_lecture.unit')} description="Required" />
              <Right>
                <FormInput t={t} name="unitName" />
              </Right>
            </Row>
            <CustomFooter>
              <OutlineButton
                title={t('create_lecture.cancel_register_button')}
                key="cancel"
                onClick={handleClosePopup}
              />
              <PrimaryButton
                title={t('create_lecture.register_button')}
                key="ok"
                htmlType="submit"
                loading={createLessonData?.loading}
              />
            </CustomFooter>
          </form>
        </Spin>
      </ModalReport>
    </FormProvider>
  )
}

export default ReportLessonModal

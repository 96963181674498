export const LOAD_NOTIFICATION_LIST = '@NOTIFICATION/LOAD_NOTIFICATION_LIST'
export const LOAD_NOTIFICATION_LIST_SUCCESS = '@NOTIFICATION/LOAD_NOTIFICATION_LIST_SUCCESS'
export const LOAD_NOTICE_SUCCESS = '@NOTIFICATION/LOAD_NOTICE_SUCCESS'

export const LOAD_NOTIFICATION_DETAIL = '@NOTIFICATION/LOAD_NOTIFICATION_DETAIL'
export const LOAD_NOTIFICATION_DETAIL_SUCCESS = '@NOTIFICATION/LOAD_NOTIFICATION_DETAIL_SUCCESS'

export const LOAD_NOTIFICATION_UNREAD = '@NOTIFICATION/LOAD_NOTIFICATION_UNREAD'
export const LOAD_NOTIFICATION_UNREAD_SUCCESS = '@NOTIFICATION/LOAD_NOTIFICATION_UNREAD_SUCCESS'
export const LOAD_NOTIFICATION_UNREAD_FAILURE = '@NOTIFICATION/LOAD_NOTIFICATION_UNREAD_FAILURE'

export const LOAD_REPOS_ERROR = '@NOTIFICATION/LOAD_REPOS_ERROR'

export const DELETE_NOTIFICATION = '@NOTIFICATION/DELETE_NOTIFICATION'
export const DELETE_NOTIFICATION_SUCCESS = '@NOTIFICATION/DELETE_NOTIFICATION_SUCCESS'

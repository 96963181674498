/* eslint-disable no-unused-vars */

import {
  put, takeLatest
} from 'redux-saga/effects'

import { notification } from 'antd'
import i18next from 'i18next'
import {
  getLessonById,
  getCourseDetail,
  submitLessonById,
  getCourseDetailUnregistered,
  voteLikeUnit,
  countViewUnit,
  createHistoryUnitApi,
  getFolderIdUpload,
  uploadFileAPI,
  createUnitLessonApi,
  changeOrderOffsetApi,
  likeCommentAPI,
  disLikeCommentAPI
} from '../../../apis'
import {
  loadLessonByIdSuccess,
  loadCouresDetailSuccess,
  submitLessonByIdSuccess,
  loadCouresDetail,
  loadCouresDetailUnregisteredSuccess,
  repoLoadingError,
  voteLikeUnitSuccess,
  countViewUnitSuccess,
  createHistorySuccess,
  getDateCompleteTimeSuccess,
  createUpdateCommentsSuccess,
  deleteCommentSuccess,
  likeCommentSuccess,
  likeCommentFailure,
  dislikeCommentSuccess,
  dislikeCommentFailure
} from './actions'

import {
  LOAD_LESSON_BY_ID,
  LOAD_COURSE_DETAIL,
  SUBMIT_LESSON_BY_ID,
  LOAD_COURSE_DETAIL_UNREGISRTERED,
  VOTE_LIKE_UNIT,
  COUNT_VIEW_UNIT,
  CREATE_HISTORY,
  UPLOAD_USER_PAGE_FOLDER_ID,
  UPLOAD_FILE,
  GET_FOLDER_TREE,
  CREATE_UNIT_LESSON,
  CHANGE_ORDER_OFFSET,
  GET_DATE_COMPLETE_TIME,
  LOAD_COMMENTS,
  CREATE_UPDATE_COMMENTS,
  DELETE_COMMENT,
  DELETE_COMMENT_FAILURE,
  LIKE_COMMENT,
  DISLIKE_COMMENT
} from './constants'
import { ERROR_MESSAGE } from '../../../utils/constants'
import { FAILURE, REQUEST, SUCCESS } from '../../../store'
import { checkUploadFileStatus } from '../../../utils'
import { createUpdateCommentsApi, deleteCommentsApi, dislikeCommentApi, getCommentsApi, getDateCompleteTimeApi, likeCommentApi, likeDislikeCommentApi } from '../../../apis/course.api'

export function* lessonByIdSaga({ payload }) {
  try {
    const { data } = yield getLessonById(payload)
    yield put(loadLessonByIdSuccess(data))
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* submitLessonByIdSaga({ payload }) {
  const { callback } = payload
  try {
    yield submitLessonById(payload)
    yield put(submitLessonByIdSuccess())
    yield put(loadCouresDetail({
      userId: payload.userId,
      courseId: payload.courseId
    }))
    callback?.done()
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* courseDetailSaga({ payload }) {
  try {
    const { data } = yield getCourseDetail(payload)
    yield put(loadCouresDetailSuccess(data))
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* courseDetailUnresiteredSaga({ payload }) {
  try {
    const { data } = yield getCourseDetailUnregistered(payload)
    yield put(loadCouresDetailUnregisteredSuccess(data))
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* voteLikeUnitSaga({ payload }) {
  const { courseId, courseUnitId, typeUnit, item } = payload
  try {
    const { code, data } = yield voteLikeUnit(courseId, courseUnitId, typeUnit)
    if (code === 200) yield put(voteLikeUnitSuccess({ data, item }))
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* countViewUnitSaga({ payload }) {
  const { courseId, lessonId, typeUnit, isVideo, callback } = payload
  try {
    const { code, data } = yield countViewUnit(courseId, lessonId, typeUnit, isVideo)
    if (code === 200) {
      yield put(countViewUnitSuccess(data))
    }
    callback?.done(data)
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* createHistoryUnit({ payload }) {
  const { courseId, lessonId, typeUnit, callback } = payload
  try {
    const { code, data } = yield createHistoryUnitApi({ courseId, lessonId, typeUnit })
    if (code === 200) {
      yield put(createHistorySuccess(data))
    }
    callback?.done(data)
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* getUserPageFolderIdSaga() {
  try {
    const { code, data } = yield getFolderIdUpload()

    if (code === 200) {
      yield put({
        type: SUCCESS(UPLOAD_USER_PAGE_FOLDER_ID),
        data
      })
    }
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* uploadFileSaga({ payload }) {
  try {
    const { onSuccess, onFailure } = payload
    const { code, data } = yield uploadFileAPI(payload)
    if (code === 200) {
      yield put({
        type: SUCCESS(UPLOAD_FILE)
      })

      yield checkUploadFileStatus({
        webviewFileId: data?.id,
        onSuccess,
        onFailure
      })
    }
  } catch (error) {
    yield put({
      type: FAILURE(UPLOAD_FILE),
      error
    })
  }
}

export function* createUnitLessonSaga({ payload }) {
  try {
    const { code, data } = yield createUnitLessonApi(payload)
    if (code === 200) {
      yield put({
        type: SUCCESS(CREATE_UNIT_LESSON),
        data: data?.unitId
      })
      notification.success({
        message: i18next.t('common.message.create_success'),
        duration: 2
      })
      payload.callback()
    }
    payload.callback()
  } catch (error) {
    yield put({
      type: FAILURE(CREATE_UNIT_LESSON),
      error
    })
    notification.error({
      message: i18next.t('common.error'),
      description: i18next.t(`errors.${error.type}`),
      duration: 2
    })
  }
}

export function* changeOrderOffset({ payload }) {
  const { courseId, offsetBefore, offsetAfter, unitId, typeUnit, source, destination } = payload
  try {
    const { code, data } = yield changeOrderOffsetApi({ courseId, offsetBefore, offsetAfter, unitId, typeUnit })

    if (code === 200) {
      yield put({
        type: SUCCESS(CHANGE_ORDER_OFFSET),
        payload: {
          source,
          destination,
          data
        }
      })
    }
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* getDateCompleteTime({ payload }) {
  const { callback } = payload
  try {
    const { code, data } = yield getDateCompleteTimeApi()
    if (code === 200) {
      yield put(getDateCompleteTimeSuccess(Number(data.value)))
    }
    callback?.done(Number(data.value))
  } catch (error) {
    yield put(repoLoadingError(error))
  }
}

export function* getComments({ payload }) {
  try {
    const { code, data: { page, limit, pages, total, result } } = yield getCommentsApi({ ...payload.data, ...payload.filter })
    if (code === 200) {
      yield put({
        type: SUCCESS(LOAD_COMMENTS),
        data: result,
        pagination: { page, limit, pages, total },
        filter: payload.filter || payload.data
      })
    }
  } catch (error) {
    yield put({
      type: FAILURE(LOAD_COMMENTS),
      error
    })
  }
}

export function* createUpdateComments({ payload }) {
  try {
    const { code, data } = yield createUpdateCommentsApi(payload.data)
    if (code === 200) {
      yield put(createUpdateCommentsSuccess(data))

      yield put({
        type: SUCCESS(CREATE_UPDATE_COMMENTS),
        data
      })

      yield put({
        type: REQUEST(LOAD_COMMENTS),
        payload: { data: { ...payload.filter, unitId: payload.data.unitId, courseId: payload.data.courseId } }
      })
    }
  } catch (error) {
    yield put({
      type: FAILURE(CREATE_UPDATE_COMMENTS),
      error
    })
  }
}

export function* deleteCommentSaga({ payload }) {
  try {
    const { code } = yield deleteCommentsApi(payload.commentId)
    if (code === 200) {
      yield put(deleteCommentSuccess())

      yield put({
        type: REQUEST(LOAD_COMMENTS),
        payload: { data: { ...payload.filter, page: 1, unitId: payload.unitId, courseId: payload.courseId } }
      })
    }
  } catch (error) {
    yield put({
      type: DELETE_COMMENT_FAILURE,
      error
    })
  }
}

export function* likeCommentSaga({ payload }) {
  try {
    const { code, data } = yield likeCommentApi(payload.commentId, payload.isLike)
    if (code === 200) {
      yield put(likeCommentSuccess(data))
    }
  } catch (error) {
    yield put(likeCommentFailure())
  }
}

export function* dislikeCommentSaga({ payload }) {
  try {
    const { code, data } = yield dislikeCommentApi(payload.commentId, payload.isDislike)
    if (code === 200) {
      yield put(dislikeCommentSuccess(data))
    }
  } catch (error) {
    yield put(dislikeCommentFailure())
  }
}

export default function* courseSaga() {
  yield takeLatest(LOAD_LESSON_BY_ID, lessonByIdSaga)
  yield takeLatest(SUBMIT_LESSON_BY_ID, submitLessonByIdSaga)
  yield takeLatest(LOAD_COURSE_DETAIL, courseDetailSaga)
  yield takeLatest(LOAD_COURSE_DETAIL_UNREGISRTERED, courseDetailUnresiteredSaga)
  yield takeLatest(VOTE_LIKE_UNIT, voteLikeUnitSaga)
  yield takeLatest(COUNT_VIEW_UNIT, countViewUnitSaga)
  yield takeLatest(CREATE_HISTORY, createHistoryUnit)
  yield takeLatest(REQUEST(UPLOAD_USER_PAGE_FOLDER_ID), getUserPageFolderIdSaga)
  yield takeLatest(REQUEST(UPLOAD_FILE), uploadFileSaga)
  yield takeLatest(REQUEST(CREATE_UNIT_LESSON), createUnitLessonSaga)
  yield takeLatest(REQUEST(CHANGE_ORDER_OFFSET), changeOrderOffset)
  yield takeLatest(GET_DATE_COMPLETE_TIME, getDateCompleteTime)
  yield takeLatest(REQUEST(LOAD_COMMENTS), getComments)
  yield takeLatest(CREATE_UPDATE_COMMENTS, createUpdateComments)
  yield takeLatest(DELETE_COMMENT, deleteCommentSaga)
  yield takeLatest(LIKE_COMMENT, likeCommentSaga)
  yield takeLatest(DISLIKE_COMMENT, dislikeCommentSaga)
}

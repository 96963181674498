export const REGISTER_EMAIL = '@REGISTER/REGISTER_EMAIL'
export const REGISTER_EMAIL_SUCCESS = '@REGISTER/REGISTER_EMAIL_SUCCESS'
export const REGISTER_EMAIL_ERROR = '@REGISTER/REGISTER_EMAIL_ERROR'
export const REGISTER_COMPANY = '@REGISTER/REGISTER_COMPANY'
export const REGISTER_COMPANY_SUCCESS = '@REGISTER/REGISTER_COMPANY_SUCCESS'
export const REGISTER_COMPANY_ERROR = '@REGISTER/REGISTER_COMPANY_ERROR'

export const CHECK_EMAIL_EXIST = '@REGISTER/CHECK_EMAIL_EXIST'
export const CHECK_EMAIL_EXIST_SUCCESS = '@REGISTER/CHECK_EMAIL_EXIST_SUCCESS'
export const CHECK_EMAIL_EXIST_ERROR = '@REGISTER/CHECK_EMAIL_EXIST_ERROR'

export const EMAIL_COMPANY_INVALID = '@REGISTER/EMAIL_COMPANY_INVALID'

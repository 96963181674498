export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME
export const S3_BUCKET_PDF_NAME = process.env.REACT_APP_S3_BUCKET_PDF_NAME

export const VIDEO_TYPE = [
  'video/mp4',
  'video/webm',
  'video/quicktime',
  'video/avi',
  'video/x-ms-wmv',
  'video',
  'video/x-matroska',
  'mp4',
  'webm',
  'oga',
  'ogv']

export const ERROR_MESSAGE = {
  ERROR_NUMBER_DATA_OVER_PLAN_PACKAGE: 'ERROR_NUMBER_DATA_OVER_PLAN_PACKAGE'
}

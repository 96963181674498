/* eslint-disable no-unused-vars */
import axios from 'axios'
import { completeUpLoadFileApi, getS3PresignedUrl, getUploadIdFromS3Api } from '../apis'
import { mapMimeToExt } from '../components/form/FormUploadDD/constants'
import { S3_BUCKET_NAME, S3_BUCKET_PDF_NAME } from './constants'

export const parseFilter = (params) => {
  if (params?.filter) {
    const { filter, ...restParams } = params
    return { ...restParams, ...filter }
  }
  return params
}

export const parseParamsToQueryString = (params) => {
  const q = new URLSearchParams()
  Object.keys(params).forEach((key) => {
    if (params[key] instanceof Array) {
      params[key].forEach((value) => q.append(key, value))
    } else if (params[key]) {
      q.append(key, params[key])
    }
  })
  return q
}

export const parseFilterArrayToStringV2 = (params) => {
  let newParams
  let string = ''
  if (params?.filter) {
    const { filter, ...restParams } = params
    newParams = { ...filter, ...restParams }
  } else {
    newParams = { ...params }
  }
  if (newParams) {
    Object.keys(newParams).forEach((key) => {
      if (Array.isArray(newParams[key]) && newParams[key].length > 0) {
        newParams[key].forEach((param) => {
          string = `${string}&${key}=${param}`
        })
      } else if (newParams[key] && !Array.isArray(newParams[key])) {
        string = `${string}&${key}=${newParams[key]}`
      }
    })
  }
  return string
}

export const checkTypeOf = (value) => Object.prototype.toString.call(value).slice(8, -1)

export const hasValuesInObject = (object) => {
  const hasValue = Object.values(object).some((value) => {
    if (checkTypeOf(value) === 'Array' && !value.length) return false
    if (checkTypeOf(value) === 'Object' && !Object.keys(value).length) return false
    return Boolean(value)
  })
  return hasValue
}

export const copyObjectUsingJSON = (object) => JSON.parse(JSON.stringify(object))

const chunkFile = (file, chunkSize) => {
  const chunks = []
  let start = 0
  let end = chunkSize

  while (start < file.size) {
    const chunk = file.slice(start, end)
    chunks.push(chunk)
    start = end
    end = start + chunkSize
  }

  return chunks
}

export const handleUploadFileByChunk = async (file) => {
  const fileExtension = mapMimeToExt[file.type]
  const fileList = [{ fileName: file.name, fileType: fileExtension }]
  const { data } = await getS3PresignedUrl({ fileList })
  let bucketName = S3_BUCKET_NAME

  const config = {
    headers: { 'content-type': file.type }
  }

  const isMp4File5GB = ['video/mp4'].includes(file.type) && (file.size > (4.9 * 1024 * 1024 * 1024)) // file is mp4 and > 4,9GB
  const isPdfFile = data[0].url.includes(S3_BUCKET_PDF_NAME)

  if (isPdfFile) {
    bucketName = S3_BUCKET_PDF_NAME
  }

  const splitPresignedUrl = data[0].preSignedURL.split('/')[3].split('?')[0]
  const objectKeyByChunk = isMp4File5GB ? `mediaconvert/${splitPresignedUrl}` : splitPresignedUrl
  const chunkSize = 100 * 1024 * 1024 // 100 MB
  const chunks = chunkFile(file, chunkSize)

  const partETags = []

  const { data: { uploadId, parts } } = await getUploadIdFromS3Api({ bucketName, keyName: objectKeyByChunk, parts: chunks.length })

  for (let i = 0; i < parts.length; i += 1) {
    const partNumber = i + 1
    // eslint-disable-next-line no-await-in-loop
    const result = await axios.put(parts[i].url, chunks[i], config)
    const etag = result.headers.etag.replaceAll('"', '')
    partETags.push({ partNumber, etag })
  }

  const { data: { location } } = await completeUpLoadFileApi({ data: { bucketName, key: objectKeyByChunk, partETags, uploadId } })

  let result = data[0].url

  return result
}

/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Popover } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import videojs from 'video.js'
import { VIDEO_WATCH_TIME } from '../../constants'

import 'video.js/dist/video-js.css'
import { saveLastViewedVideoAPI } from '../../apis'
import { useHistories, usePrevious, useProfile, useUnitLesson } from '../../hooks'
import useQuery from '../../hooks/useQuery'
import { STORAGE, getFileFromS3, getLocalStorage, roundNumber, setLocalStorage } from '../../utils'

const VideoStyled = styled.div`
  position: relative;
    .overlay__request-password {
      position: absolute;
      inset: 0;
      cursor: pointer;
    }
`

const DEFAULT_OPTION = 'Default'

export const getLessonsViewedWithoutCompleted = () => JSON.parse(getLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED)) || []

export const VideoJS = (props) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const {
    options,
    onReady,
    tracks = [],
    isSubmitPrev,
    SetIsSubmitPrev,
    courseId,
    lessonId,
    countViewUnit,
    typeUnit,
    onChangeResolution,
    lessonDetail,
    resolutionDiv,
    isIpadOrIphone,
    setIsShowResolutionSelect,
    setIsDefaultResolution,
    submitLesson,
    units = [],
    ...rest } = props

  const { t } = useTranslation()
  const history = useHistories()
  const params = useParams()
  const query = useQuery()
  // End hooks

  // Use states
  const [isPlayed, setIsPlayed] = useState(false)
  const [isViewed, setIsViewed] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [isShowResolution, setIsShowResolution] = useState(false)
  const [visibleModalRequestPassword, setVisibleModalRequestPassword] = useState(false)
  const [statusRequestPassword, setStatusRequestPassword] = useState(
    { lessonConfirmPassword: lessonDetail?.lessonConfirmPassword,
      time: 0
    }
  )

  const [isFirstPressPlay, setIsFirstPressPlay] = useState(false)
  const { profile } = useProfile()
  const { createHistoryAction } = useUnitLesson({
    userId: profile.userId,
    courseId,
    lessonId,
    isCreateHistory: true
  })

  const dateCompleteTime = JSON.parse(getLocalStorage(STORAGE.DATE_COMPLETE_TIME))

  const [newHistoryId, setNewHistoryId] = useState(undefined)
  const [currentVideoPlayTime, setCurrentVideoPlayTime] = useState(0)
  const [isSeekedForwardVideoCompleted, setIsSeekedForwardVideoCompleted] = useState(false)
  const [resolutionInfo, setResolutionInfo] = useState(DEFAULT_OPTION)
  // End use states

  const currentVideoPlayTimePrev = usePrevious(currentVideoPlayTime)

  const optionResolution = (lessonDetail?.resolutions?.map((item) => Number(item?.type)).sort((a, b) => b - a)).concat(DEFAULT_OPTION)

  // TODO: E-5106
  // const unitsVideo = useMemo(() => units && units.filter((unit) => unit.typeLesson === LESSON_DETAIL_TYPE.VIDEO), [units])
  const autoPlay = useMemo(() => query.get('autoPlay') === 'true', [query])
  const lessonsViewedWithoutCompleted = getLessonsViewedWithoutCompleted()
  const indexOfLessonViewedWithoutCompleted = [...getLessonsViewedWithoutCompleted()].findIndex((lesson) => lesson.lessonId === lessonId)
  let newLessonsViewedWithoutCompleted = [...lessonsViewedWithoutCompleted]
  let isCountFromStorage = newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted]?.isCount || false
  let isSeekFromStorage = newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted]?.isSeek || false

  useEffect(() => {
    if (lessonDetail?.resolutions?.length === 0) {
      setIsShowResolutionSelect(false)
    }
    if (isCountFromStorage) {
      setIsViewed(isCountFromStorage)
    }
  }, [lessonDetail?.resolutions?.length, isCountFromStorage])

  const onSubmitLesson = () => {
    submitLesson({ queryParams: { historyId: newHistoryId } })
    setIsCompleted(true)

    if (indexOfLessonViewedWithoutCompleted !== -1) {
      lessonsViewedWithoutCompleted.splice(-indexOfLessonViewedWithoutCompleted, 1)
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(lessonsViewedWithoutCompleted))
    }
    saveLastViewedVideoAPI({
      courseId,
      lessonId,
      data: { lastViewedAt: 0 }
    })
  }

  const saveIsCountStorage = (value) => {
    if (indexOfLessonViewedWithoutCompleted !== -1) {
      newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].isCount = value
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
    }
  }

  const saveIsSeekStorage = (value) => {
    if (indexOfLessonViewedWithoutCompleted !== -1) {
      newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].isSeek = value
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
    }
  }

  const handleChangeResolution = (event) => {
    const { value } = event?.target
    if (value === resolutionInfo) {
      setIsShowResolution(true)
    } else {
      setIsShowResolution(false)
    }
    setResolutionInfo(value)

    if (value === DEFAULT_OPTION) setIsDefaultResolution(true)
    else setIsDefaultResolution(false)

    const currentTime = roundNumber(playerRef.current.currentTime())
    const duration = roundNumber(playerRef.current.duration())

    if ((currentTime / duration) >= VIDEO_WATCH_TIME && !isViewed && !isCountFromStorage) {
      countViewUnit({
        courseId,
        lessonId,
        typeUnit,
        isVideo: true
      })
      saveIsCountStorage(true)
      setIsViewed(true)
    }

    if (isViewed && !isSeekedForwardVideoCompleted && duration - currentTime <= dateCompleteTime && !isCompleted && !isSeekFromStorage) {
      onSubmitLesson()
    }

    const player = playerRef.current
    const path = value === DEFAULT_OPTION ? lessonDetail?.path : lessonDetail?.resolutions?.find((item) => item?.type === value.toString())?.path
    const lastPlaybackRate = player?.cache_?.lastPlaybackRate || 1
    player.src([
      {
        src: !isIpadOrIphone ? getFileFromS3(path) : `${getFileFromS3(path)}`,
        type: 'video/mp4'
      },
      {
        src: !isIpadOrIphone ? getFileFromS3(path) : `${getFileFromS3(path)}`,
        type: 'video/webm'
      },
      {
        src: !isIpadOrIphone ? getFileFromS3(path) : `${getFileFromS3(path)}`,
        type: 'video/3gp'
      }])

    player.defaultPlaybackRate(lastPlaybackRate)
    player.load()
    player.currentTime(currentTime - 1)
    player.play()
  }

  const handleOnPlay = () => {
    if (!isFirstPressPlay) {
      if (indexOfLessonViewedWithoutCompleted !== -1) {
        setNewHistoryId(newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].historyId)
      } else {
        createHistoryAction({ courseId,
          lessonId,
          typeUnit,
          callback: {
            done: (data) => {
              setNewHistoryId(data)
              newLessonsViewedWithoutCompleted.push({ lessonId, historyId: data, isSeek: false, isCount: false })
              setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
              setIsFirstPressPlay(true)
            }
          } })
      }
    }
    setIsPlayed(true)

    if (!isShowResolution) setIsShowResolution(true)
  }

  const handleOnPause = () => {
    // FOR save last viewed
    const currentTime = Math.floor(playerRef.current.currentTime())
    const duration = Math.floor(playerRef.current.duration())
    const isEnded = currentTime === duration
    const isSeekingForward = currentTime > (currentVideoPlayTimePrev + 1) // because currentTime updated immediately after seeked, so must use currentVideoPlayTimePrev

    if (!isSeekingForward) {
    // Set last viewed to zero if the user finishes watching the video.
      saveLastViewedVideoAPI({
        courseId,
        lessonId,
        data: { lastViewedAt: ((duration - currentTime) <= dateCompleteTime || isEnded) ? 0 : currentTime }
      })
    }
  }

  const handleOnEnded = async () => {
    playerRef.current.controlBar.progressControl.enable()
    const player = playerRef.current
    setIsViewed(false)
    setIsCompleted(false)
    setIsSeekedForwardVideoCompleted(false)
    player.currentTime(0)
    player.pause()
    setNewHistoryId(undefined)
    setIsFirstPressPlay(false)
    if (!isShowResolution) setIsShowResolution(true)
    playerRef.current.controls(true)
    setIsFirstPressPlay(false)
  }

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      playerRef.current = videojs(videoElement, options, () => {
        onReady?.(playerRef.current, videoElement)
      })
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
    playerRef.current.aspectRatio('16:9')
    playerRef.current.on('userinactive', () => {
      if (!playerRef.current.paused()) {
        setTimeout(() => {
          setIsShowResolution(false)
        }, 310)
      }
    })

    playerRef.current.on('useractive', () => {
      setIsShowResolution(true)
    })
  }, [options, videoRef])

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  useEffect(() => {
    if (playerRef && playerRef.current && isSubmitPrev) {
      if (playerRef.current.currentTime() > 10) {
        playerRef.current.currentTime(playerRef.current.currentTime() - 10)
      } else {
        playerRef.current.currentTime(0)
      }
    }
    SetIsSubmitPrev(false)
  }, [playerRef, playerRef.current, isSubmitPrev])

  // Time updating
  const handleTimeUpdate = () => {
    if (playerRef && playerRef.current) {
      const isPaused = playerRef.current.paused()
      if (!isPaused) {
        const currentTime = Math.floor(playerRef.current.currentTime())
        const duration = Math.floor(playerRef.current.duration())
        setCurrentVideoPlayTime(currentTime)

        // For count view
        if ((currentTime / duration) >= VIDEO_WATCH_TIME && !isViewed && !isCountFromStorage) {
          countViewUnit({
            courseId,
            lessonId,
            typeUnit,
            isVideo: true
          })

          saveIsCountStorage(true)
          setIsViewed(true)
        }

        if (isViewed && !isSeekedForwardVideoCompleted && duration - currentTime <= dateCompleteTime && !isCompleted && !isSeekFromStorage) {
          onSubmitLesson()
        }

        if (duration - currentTime <= dateCompleteTime && isSeekFromStorage) {
          if (indexOfLessonViewedWithoutCompleted !== -1) {
            lessonsViewedWithoutCompleted.splice(-indexOfLessonViewedWithoutCompleted, 1)
            setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(lessonsViewedWithoutCompleted))
            saveLastViewedVideoAPI({
              courseId,
              lessonId,
              data: { lastViewedAt: 0 }
            })
            setIsCompleted(true)
          }
        }
      }
    }
  }

  // Time seeking
  const handleOnSeeking = () => {
    if (isPlayed && playerRef?.current) {
      const currentTime = Math.floor(playerRef.current.currentTime())
      const isForwarding = currentTime > currentVideoPlayTimePrev // because currentTime updated immediately after seeked, so must use currentVideoPlayTimePrev
      if (isForwarding && !isSeekedForwardVideoCompleted && !isCompleted) {
        setIsSeekedForwardVideoCompleted(true)
        saveIsSeekStorage(true)
      }
    }
  }

  // FOR resolution
  useEffect(() => {
    resolutionDiv?.insertAdjacentHTML('afterend', '<div class="resolution-div"></div>')
  }, [resolutionDiv])

  // FOR set current time of video (by previous watch video: action pause video)
  useEffect(() => {
    const videoElement = videoRef.current
    if (videoElement && Boolean(lessonDetail.lastViewedAt)) {
      videoElement.currentTime = lessonDetail.lastViewedAt
      setCurrentVideoPlayTime(lessonDetail.lastViewedAt)
    }
  }, [videoRef, lessonDetail])

  const renderResolutionInfo = (item) => (item === DEFAULT_OPTION ? t('common.default') : `${item}p`)

  return (
    <VideoStyled>
      <div data-vjs-player>
        <video
          id="my-video"
          {...rest}
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          onTimeUpdate={handleTimeUpdate}
          onPlay={handleOnPlay}
          onPause={handleOnPause}
          onEnded={handleOnEnded}
          onSeeking={handleOnSeeking}
          // autoPlay={autoPlay}
        >
          {tracks.map((trackInfo) => (
            <track {...trackInfo} />
          ))}
        </video>
        {isShowResolution ? (
          <Popover
            getPopupContainer={() => document.getElementById('my-video')}
            content={(
              <div className="popup-content">{optionResolution?.map((item, index) => (
                <button
                  key={index}
                  value={item}
                  className={resolutionInfo.toString() === item.toString() ? 'option-select active' : 'option-select'}
                  onClick={handleChangeResolution}
                >
                  {renderResolutionInfo(item)}
                </button>
              ))}
              </div>
            )}
            trigger={['hover', 'click']}
            mouseEnterDelay={0}
          >
            <Button
              type="text"
              className="resolution-btn"
            >
              {renderResolutionInfo(resolutionInfo)}
            </Button>
          </Popover>
        ) : <></>}
      </div>
    </VideoStyled>
  )
}

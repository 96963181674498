export const LOAD_REPORT = '@REPORT/LOAD_REPORT'
export const LOAD_REPORT_SUCCESS = '@REPORT/LOAD_REPORT_SUCCESS'
export const LOAD_REPORT_ERROR = '@REPORT/LOAD_REPORT_ERROR'

export const UPDATE_ANSWER_REPORT = '@REPORT/UPDATE_ANSWER_REPORT'

export const SUBMIT_REPORT = '@REPORT/SUBMIT_REPORT'
export const SUBMIT_REPORT_SUCCESS = '@REPORT/SUBMIT_REPORT_SUCCESS'
export const SUBMIT_REPORT_ERROR = '@REPORT/SUBMIT_REPORT_ERROR'

export const LOAD_REPOS_ERROR = '@REPORT/LOAD_REPOS_ERROR'

import styled from 'styled-components'
import { Space } from 'antd'
import { MEDIA_WIDTHS } from '../../../../themes'

export const Right = styled.div`
  display: flex;
  width: 70%;
  padding: ${({ noInput }) => (noInput ? '16px 16px 16px 27px' : '16px')};
  flex-direction: column;
  word-break: break-all;
	gap: ${({ gap }) => (gap || 0)};

  @media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
    width: 100%;
  }

  &.row__box {
    flex-direction: row;
    justify-content: space-between;
  }


  .ant-form-item {
    margin-bottom: 0;
  }

  &.form-editor-content-question {
    .ant-row {
      margin-bottom: 0 !important;
    }
    p {
      margin-bottom: 0;
    }
  }
`
export const SpaceWrapper = styled(Space)`
  display: flex;
  flex-direction: column;
  .ant-picker {
    width: 100%;
  }
  label {
    font-size: ${({ theme }) => theme.size_14};
    cursor: pointer;
  }
  label.ant-radio-wrapper {
    margin-right: 0;
  }

  .release_time {
    width: 100%;
    display: flex;
    margin-left: 24px;
  }
`
export const TextWrapper = styled.p`
  color: ${({ color, theme }) => theme[color]};
  padding: 0;
  margin: 0;
  font-size: ${({ fontSize, theme }) => theme[fontSize] || '16px'};
  font-weight: ${({ fontWeight, theme }) => theme[fontWeight] || '400'};
`
export const CustomFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`

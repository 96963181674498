/* eslint-disable no-console */
import { put, takeLatest } from 'redux-saga/effects'
import {
  getMaintainNoticeSuccess,
  getMaintainNoticeFailure,
  getStatusMaintainSuccess,
  getStatusMaintainFailure
} from './actions'
import { GET_MAINTAIN_NOTICE_REQUEST, GET_STATUS_MAINTAIN_REQUEST } from './constants'

import { getMaintainNoticeAPI } from '../../apis'

export function* getMaintainNoticeSaga() {
  try {
    const { data } = yield getMaintainNoticeAPI()
    yield put(getMaintainNoticeSuccess(data))
  } catch (error) {
    yield put(getMaintainNoticeFailure(error))
  }
}

export function* getStatusMaintainSaga() {
  try {
    const { data } = yield getMaintainNoticeAPI()
    yield put(getStatusMaintainSuccess(data))
  } catch (error) {
    yield put(getStatusMaintainFailure(error))
  }
}

export default function* globalSaga() {
  yield takeLatest(GET_MAINTAIN_NOTICE_REQUEST, getMaintainNoticeSaga)
  yield takeLatest(GET_STATUS_MAINTAIN_REQUEST, getStatusMaintainSaga)
}

export const LOGIN_REQUEST = '@AUTH/LOGIN_REQUEST'
export const LOGIN_SUCCESS = '@AUTH/LOGIN_SUCCESS'
export const LOGIN_FAILURE = '@AUTH/LOGIN_FAILURE'
export const RESET_LOGIN_ERROR = '@AUTH/RESET_LOGIN_ERROR'

export const LOGOUT_REQUEST = '@AUTH/LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = '@AUTH/LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = '@AUTH/LOGOUT_FAILURE'

export const GET_EMAIL_RESET_REQUEST = '@AUTH/GET_EMAIL_RESET_REQUEST'
export const GET_EMAIL_RESET_SUCCESS = '@AUTH/GET_EMAIL_RESET_SUCCESS'
export const GET_EMAIL_RESET_ERROR = '@AUTH/GET_EMAIL_RESET_ERROR'

export const VERIFY_TOKEN_REQUEST = '@AUTH/VERIFY_TOKEN_REQUEST'
export const VERIFY_TOKEN_SUCCESS = '@AUTH/VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAILURE = '@AUTH/VERIFY_TOKEN_FAILURE'

export const SETTING_PASSWORD_REQUEST = '@AUTH/SETTING_PASSWORD_REQUEST'
export const SETTING_PASSWORD_SUCCESS = '@AUTH/SETTING_PASSWORD_SUCCESS'
export const SETTING_PASSWORD_FAILURE = '@AUTH/SETTING_PASSWORD_FAILURE'

export const GET_EMAIL_FROM_TOKEN_REQUEST = '@AUTH/GET_EMAIL_FROM_TOKEN_REQUEST'
export const GET_EMAIL_FROM_TOKEN_SUCCESS = '@AUTH/GET_EMAIL_FROM_TOKEN_SUCCESS'
export const GET_EMAIL_FROM_TOKEN_FAILURE = '@AUTH/GET_EMAIL_FROM_TOKEN_FAILURE'

export const LOAD_WORKSPACES_REQUEST = '@AUTH/LOAD_WORKSPACES_REQUEST'
export const LOAD_WORKSPACES_SUCCESS = '@AUTH/LOAD_WORKSPACES_SUCCESS'
export const LOAD_WORKSPACES_FAILURE = '@AUTH/LOAD_WORKSPACES_FAILURE'

export const RESET_STATE_REQUEST = '@AUTH/RESET_STATE_REQUEST'

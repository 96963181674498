/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Avatar, Dropdown, Image, Menu } from 'antd'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { StyledContent, StyledMenu, StyledWrapper } from './styled'
import { Linkify, TextNormal, TextPrimary } from '../../../../components/text'
import { ICON_DELETE, ICON_DETAIL, ICON_EDIT_TALK_BOARD, ICON_PROFILE, SHOWLESS_ICON, SHOWMORE_ICON } from '../../../../assets'
import Like from '../../../../components/like'
import Dislike from '../../../../components/dislike'
import { useCourseDetail, useProfile } from '../../../../hooks'
import { FONT_SIZE_COMMENT_TALKBOARD, LINE_HEIGHT_TOPIC_TALKBOARD, TAB_KEYS } from '../../../../constants'
import { dateFormat, getFileFromS3 } from '../../../../utils'

const Comment = ({ comment }) => {
  // Props
  const { user, comment: content, id, createdAt, countLike, countDislike, isLike, isDislike, isRead, ownerDeleted, unitId, courseId } = comment
  // End props

  // Use hooks
  const { t } = useTranslation()
  const { profile } = useProfile()
  const {
    comments: { filter, isLiking, isDisliking },
    setCommentForEditAction,
    deleteCommentAction,
    likeCommentAction,
    dislikeCommentAction
  } = useCourseDetail({
    userId: profile.userId
  })

  // End use hooks

  // Use states
  const [isDisplayContentWithoutExpand, setIsDisplayContentWithoutExpand] = useState(false)
  const [isExpand, setIsExpand] = useState(false)
  // End use states
  const isSelfComment = user?.id === profile.userId

  const contentRef = useRef()

  useEffect(() => {
    const condition = Math.floor((contentRef.current?.scrollHeight / FONT_SIZE_COMMENT_TALKBOARD) / LINE_HEIGHT_TOPIC_TALKBOARD) >= 4
    setIsExpand(condition)
  }, [content])

  // Action like/dislike
  const handleLike = () => likeCommentAction({ commentId: id, isLike: isLike === 1 ? 0 : 1 })
  const handleDisLike = () => dislikeCommentAction({ commentId: id, isDislike: isDislike === 1 ? 0 : 1 })

  const handleDeleteComment = () => {
    if (unitId) {
      deleteCommentAction({ commentId: id, filter, unitId })
    } else {
      deleteCommentAction({ commentId: id, filter, courseId })
    }
    setCommentForEditAction({})
  }

  const onExpand = () => {
    setIsDisplayContentWithoutExpand((prev) => !prev)
  }

  const handleEditComment = useCallback(() => {
    const element = document.getElementById('formEditComment')
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    setCommentForEditAction({ ...comment })
  }, [comment])

  const menu = useMemo(() => {
    const items = [
      {
        key: '1',
        label: (
          <StyledMenu onClick={handleEditComment}>
            <ICON_EDIT_TALK_BOARD />
            <TextNormal className="text">{t('profile.home.edit')}</TextNormal>
          </StyledMenu>
        ),
        isShow: ownerDeleted !== 1 && isSelfComment
      },
      {
        key: '2',
        label: (
          <StyledMenu onClick={handleDeleteComment}>
            <ICON_DELETE />
            <TextNormal className="text">{t('common.delete')}</TextNormal>
          </StyledMenu>
        ),
        isShow: ownerDeleted !== 1 && isSelfComment
      }
    ].filter((m) => m.isShow)

    return {
      items,
      content: <Menu
        style={{ borderRadius: 4 }}
        items={items}
      /> }
  }, [ownerDeleted, isSelfComment, t, handleEditComment])

  return (
    <StyledWrapper
      className="comment"
      isRead={isRead}
      isSelfComment={isSelfComment}
    >
      <div className="info-group">
        <div className="user-info">
          <Avatar size={27} src={getFileFromS3(user?.imagePath) || ICON_PROFILE} alt="img-avatar" />
          <TextNormal color="grey" fontWeight="500">{user?.fullName}</TextNormal>
        </div>
        <div className="time-info">
          <TextNormal fontSize="size_12" color="grey">{dateFormat(createdAt) || ''}</TextNormal>
          {menu.items.length > 0 && (
          <Dropdown
            placement="bottomRight"
            overlay={menu.content}
            trigger={['click']}
          >
            <ICON_DETAIL className="button-read" />
          </Dropdown>
          )}
        </div>
      </div>
      <StyledContent lineHeight={LINE_HEIGHT_TOPIC_TALKBOARD} isDisplayContentWithoutExpand={isDisplayContentWithoutExpand}>
        <TextNormal
          ref={contentRef}
          className="content-group-des"
          color="grey"
          fontSize="size_20"
        >
          <Linkify>{content}</Linkify>
        </TextNormal>
        {/* Show more/less */}
        {isExpand
          && (
          <div className="content-group-expand" aria-hidden="true" onClick={onExpand}>
            <TextPrimary fontSize="size_14" color="green">
              {t(isDisplayContentWithoutExpand ? 'course_detail.show_more' : 'course_detail.show_less')}
            </TextPrimary>
            <Image preview={false} src={isDisplayContentWithoutExpand ? SHOWMORE_ICON : SHOWLESS_ICON} />
          </div>
          )}
      </StyledContent>
      {!ownerDeleted
      && (
      <div className="action-group">
        <Like
          totalLike={countLike}
          isLiking={isLiking}
          isLike={Boolean(isLike)}
          onClick={handleLike}
        />
        <Dislike
          totalDislike={countDislike}
          isDisliking={isDisliking}
          isDislike={Boolean(isDislike)}
          onClick={handleDisLike}
        />
      </div>
      )}
      {isRead === 0 && <div className="icon-unread" />}
    </StyledWrapper>
  )
}

export default memo(Comment)

// Daily reports
export const LOAD_DAILY_REPORTS = '@DAILY_REPORT/LOAD_DAILY_REPORTS'
export const LOAD_DAILY_REPORT = '@DAILY_REPORT/LOAD_DAILY_REPORT'
export const CREATE_DAILY_REPORT = '@DAILY_REPORT/CREATE_DAILY_REPORT'
export const EDIT_DAILY_REPORT = '@DAILY_REPORT/EDIT_DAILY_REPORT'
export const DELETE_DAILY_REPORT = '@DAILY_REPORT/DELETE_DAILY_REPORT'
export const LOAD_USERS_INTERACTED_DAILY_REPORT = '@DAILY_REPORT/LOAD_USERS_INTERACTED_DAILY_REPORT'
export const LIKE_DAILY_REPORT = '@DAILY_REPORT/LIKE_DAILY_REPORT'
export const DISLIKE_DAILY_REPORT = '@DAILY_REPORT/DISLIKE_DAILY_REPORT'
export const MARK_READ_DAILY_REPORT = '@DAILY_REPORT/MARK_READ_DAILY_REPORT'
export const LOAD_COMMENTS_DAILY_REPORT = '@DAILY_REPORT/LOAD_COMMENTS_DAILY_REPORT'
export const CREATE_DAILY_REPORT_COMMENT = '@DAILY_REPORT/CREATE_DAILY_REPORT_COMMENT'
export const EDIT_DAILY_REPORT_COMMENT = '@DAILY_REPORT/EDIT_DAILY_REPORT_COMMENT'
export const DELETE_DAILY_REPORT_COMMENT = '@DAILY_REPORT/DELETE_DAILY_REPORT_COMMENT'
export const SET_COMMENT_FOR_EDIT = '@DAILY_REPORT/SET_COMMENT_FOR_EDIT'
export const LIKE_DAILY_REPORT_COMMENT = '@DAILY_REPORT/LIKE_DAILY_REPORT_COMMENT'
export const DISLIKE_DAILY_REPORT_COMMENT = '@DAILY_REPORT/DISLIKE_DAILY_REPORT_COMMENT'
export const LOAD_USERS_INTERACTED_DAILY_REPORT_COMMENT = '@DAILY_REPORT/LOAD_USERS_INTERACTED_DAILY_REPORT_COMMENT'
export const SET_COMPLETE_DAILY_REPORT = '@DAILY_REPORT/SET_COMPLETE_DAILY_REPORT'
export const GET_UNREAD = '@DAILY_REPORT/GET_UNREAD'
export const GET_PREV_NEXT = '@DAILY_REPORT/GET_PREV_NEXT'

export const RESET_FILTER = '@DAILY_REPORT/RESET_FILTER'

// Templates
export const LOAD_TEMPLATES = '@DAILY_REPORT/LOAD_TEMPLATES'
export const LOAD_TEMPLATE_DETAIL = '@DAILY_REPORT/LOAD_TEMPLATE_DETAIL'
export const DELETE_TEMPLATE = '@TEMPLATE/DELETE_TEMPLATE'
export const EDIT_TEMPLATE = '@TEMPLATE/EDIT_TEMPLATE'
export const CREATE_TEMPLATE = '@TEMPLATE/CREATE_TEMPLATE'

export const FOLDER_REPORT = '@DAILY_REPORT/FOLDER_REPORT'
export const CREATE_FOLDER_REPORT = '@DAILY_REPORT/CREATE_FOLDER_REPORT'
export const DELETE_FOLDER_REPORT = '@DAILY_REPORT/DELETE_FOLDER_REPORT'
export const UPDATE_FOLDER_REPORT = '@DAILY_REPORT/UPDATE_FOLDER_REPORT'
export const MOVE_DAILY_REPORT_TO_FOLDER = '@DAILY_REPORT/MOVE_DAILY_REPORT_TO_FOLDER'

/* eslint-disable react/prop-types */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Spin } from 'antd'
import { ModalReport, OutlineButton, PrimaryButton } from '../../../../../components'
import { CustomFooter } from '../../../../course/components/report-lesson-modal/styled'

const ModalDeleteFolder = ({
  isShowDeleteModalFolder,
  setIsShowDeleteModalFolder,
  onSubmit,
  isLoadingDelete
}) => {
  const { t } = useTranslation()
  return (
    <ModalReport
      isModalVisible={isShowDeleteModalFolder}
      setIsModalVisible={setIsShowDeleteModalFolder}
      description={t('dailyReports.delete.confirmDelete')}
      descriptionSize="size_20"
      isFooter={false}
      onCancel={() => setIsShowDeleteModalFolder(false)}
      closable
    >
      <Spin spinning={isLoadingDelete}>
        <Divider />

        <CustomFooter>
          <PrimaryButton
            title={t('common.delete')}
            key="ok"
            type="submit"
            borderRadius={8}
            onClick={onSubmit}
          />
          <OutlineButton
            title={t('create_lecture.cancel_register_button')}
            key="cancel"
            borderRadius={8}
            onClick={() => setIsShowDeleteModalFolder(false)}
          />
        </CustomFooter>
      </Spin>
    </ModalReport>
  )
}

export default ModalDeleteFolder

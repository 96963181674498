export const LOAD_COMMENT_LIST = '@TALK_BOARD/LOAD_COMMENT_LIST'
export const LOAD_COMMENT_LIST_SUCCESS = '@TALK_BOARD/LOAD_COMMENT_LIST_SUCCESS'
export const LOAD_COMMENT_LIST_FAILURE = '@TALK_BOARD/LOAD_COMMENT_LIST_FAILURE'

export const CREATE_COMMENT_REQUEST = '@TALK_BOARD/CREATE_COMMENT_REQUEST'
export const CREATE_COMMENT_SUCCESS = '@TALK_BOARD/CREATE_COMMENT_SUCCESS'
export const CREATE_COMMENT_FAILURE = '@TALK_BOARD/CREATE_COMMENT_FAILURE'

export const UPLOAD_FILES_COMMENT_REQUEST = '@TALK_BOARD/UPLOAD_FILES_COMMENT_REQUEST'
export const UPLOAD_FILES_COMMENT_SUCCESS = '@TALK_BOARD/UPLOAD_FILES_COMMENT_SUCCESS'
export const UPLOAD_FILES_COMMENT_FAILURE = '@TALK_BOARD/UPLOAD_FILES_COMMENT_FAILURE'

export const UPLOAD_FILES_CREATE_TB_REQUEST = '@TALK_BOARD/UPLOAD_FILES_CREATE_TB_REQUEST'
export const UPLOAD_FILES_CREATE_TB_SUCCESS = '@TALK_BOARD/UPLOAD_FILES_CREATE_TB_SUCCESS'

export const LOAD_TAG = '@TALK_BOARD/LOAD_TAG'
export const LOAD_TAG_SUCCESS = '@TALK_BOARD/LOAD_TAG_SUCCESS'
export const LOAD_TAG_ERROR = '@TALK_BOARD/LOAD_TAG_ERROR'

export const LOAD_GROUP = '@TALK_BOARD/LOAD_GROUP'
export const LOAD_GROUP_SUCCESS = '@TALK_BOARD/LOAD_GROUP_SUCCESS'
export const LOAD_GROUP_ERROR = '@TALK_BOARD/LOAD_GROUP_ERROR'

export const LOAD_ATTRIBUTE = '@TALK_BOARD/LOAD_ATTRIBUTE'
export const LOAD_ATTRIBUTE_SUCCESS = '@TALK_BOARD/LOAD_ATTRIBUTE_SUCCESS'
export const LOAD_ATTRIBUTE_ERROR = '@TALK_BOARD/LOAD_ATTRIBUTE_ERROR'

export const CREATE_TALK_BOARD = '@TALK_BOARD/CREATE_TALK_BOARD'
export const CREATE_TALK_BOARD_SUCCESS = '@TALK_BOARD/CREATE_TALK_BOARD_SUCCESS'
export const CREATE_TALK_BOARD_ERROR = '@TALK_BOARD/CREATE_TALK_BOARD_ERROR'

export const LOAD_TALK_BOARD = '@TALK_BOARD/LOAD_TALK_BOARD'
export const LOAD_TALK_BOARD_SUCCESS = '@TALK_BOARD/LOAD_TALK_BOARD_SUCCESS'
export const LOAD_TALK_BOARD_ERROR = '@TALK_BOARD/LOAD_TALK_BOARD_ERROR'

export const LOAD_UNREAD_TALK_BOARD = '@TALK_BOARD/LOAD_UNREAD_TALK_BOARD'
export const LOAD_UNREAD_TALK_BOARD_SUCCESS = '@TALK_BOARD/LOAD_UNREAD_TALK_BOARD_SUCCESS'
export const LOAD_UNREAD_TALK_BOARD_ERROR = '@TALK_BOARD/LOAD_UNREAD_TALK_BOARD_ERROR'

export const DELETE_TALK_BOARD = '@TALK_BOARD/DELETE_TALK_BOARD'
export const DELETE_TALK_BOARD_SUCCESS = '@TALK_BOARD/DELETE_TALK_BOARD_SUCCESS'
export const DELETE_TALK_BOARD_ERROR = '@TALK_BOARD/DELETE_TALK_BOARD_ERROR'

export const LIKE_COMMENT_REQUEST = '@TALK_BOARD/LIKE_COMMENT_REQUEST'
export const LIKE_COMMENT_SUCCESS = '@TALK_BOARD/LIKE_COMMENT_SUCCESS'
export const LIKE_COMMENT_FAILURE = '@TALK_BOARD/LIKE_COMMENT_FAILURE'

export const DISLIKE_COMMENT_REQUEST = '@TALK_BOARD/DISLIKE_COMMENT_REQUEST'
export const DISLIKE_COMMENT_SUCCESS = '@TALK_BOARD/DISLIKE_COMMENT_SUCCESS'
export const DISLIKE_COMMENT_FAILURE = '@TALK_BOARD/DISLIKE_COMMENT_FAILURE'

export const LOAD_USER_LIKE_COMMENT_REQUEST = '@TALK_BOARD/LOAD_USER_LIKE_COMMENT_REQUEST'
export const LOAD_USER_LIKE_COMMENT_SUCCESS = '@TALK_BOARD/LOAD_USER_LIKE_COMMENT_SUCCESS'
export const LOAD_USER_LIKE_COMMENT_FAILURE = '@TALK_BOARD/LOAD_USER_LIKE_COMMENT_FAILURE'

export const LOAD_USER_DISLIKE_COMMENT_REQUEST = '@TALK_BOARD/LOAD_USER_DISLIKE_COMMENT_REQUEST'
export const LOAD_USER_DISLIKE_COMMENT_SUCCESS = '@TALK_BOARD/LOAD_USER_DISLIKE_COMMENT_SUCCESS'
export const LOAD_USER_DISLIKE_COMMENT_FAILURE = '@TALK_BOARD/LOAD_USER_DISLIKE_COMMENT_FAILURE'

export const READ_COMMENT_REQUEST = '@TALK_BOARD/READ_COMMENT_REQUEST'
export const READ_COMMENT_SUCCESS = '@TALK_BOARD/READ_COMMENT_SUCCESS'
export const READ_COMMENT_FAILURE = '@TALK_BOARD/READ_COMMENT_FAILURE'
export const LOAD_TALK_BOARD_UPDATE = '@TALK_BOARD/LOAD_TALK_BOARD_UPDATE'
export const LOAD_TALK_BOARD_UPDATE_SUCCESS = '@TALK_BOARD/LOAD_TALK_BOARD_UPDATE_SUCCESS'
export const LOAD_TALK_BOARD_UPDATE_ERROR = '@TALK_BOARD/LOAD_TALK_BOARD_UPDATE_ERROR'

export const UPDATE_TALK_BOARD = '@TALK_BOARD/UPDATE_TALK_BOARD'
export const UPDATE_TALK_BOARD_SUCCESS = '@TALK_BOARD/UPDATE_TALK_BOARD_SUCCESS'
export const UPDATE_TALK_BOARD_ERROR = '@TALK_BOARD/UPDATE_TALK_BOARD_ERROR'

export const LOAD_TALK_BOARD_DETAIL = '@TALK_BOARD/LOAD_TALK_BOARD_DETAIL'
export const LOAD_TALK_BOARD_DETAIL_SUCCESS = '@TALK_BOARD/LOAD_TALK_BOARD_DETAIL_SUCCESS'
export const LOAD_TALK_BOARD_DETAIL_FAILURE = '@TALK_BOARD/LOAD_TALK_BOARD_DETAIL_FAILURE'

export const LIKE_TALK_BOARD = '@TALK_BOARD/LIKE_TALK_BOARD'
export const DISLIKE_TALK_BOARD = '@TALK_BOARD/DISLIKE_TALK_BOARD'
export const CHECK_COMPLETE_TALK_BOARD = '@TALK_BOARD/CHECK_COMPLETE_TALK_BOARD'

export const LOAD_USER_LIKE_TALK_BOARD = '@TALK_BOARD/LOAD_USER_LIKE_TALK_BOARD'
export const LOAD_USER_DISLIKE_TALK_BOARD = '@TALK_BOARD/LOAD_USER_DISLIKE_TALK_BOARD'
export const LOAD_USER_CHECK_COMPLETE_TALK_BOARD = '@TALK_BOARD/LOAD_USER_CHECK_COMPLETE_TALK_BOARD'
export const READ_TALK_BOARD = '@TALK_BOARD/READ_TALK_BOARD'
export const LOAD_USERS_ACTIVE_TALK_BOARD = '@TALK_BOARD/LOAD_USERS_ACTIVE_TALK_BOARD'
export const LOAD_USERS_ACTIVE_COMMENT = '@TALK_BOARD/LOAD_USERS_ACTIVE_COMMENT'

export const UPDATE_COMMENT_REQUEST = '@TALK_BOARD/UPDATE_COMMENT_REQUEST'
export const UPDATE_COMMENT_SUCCESS = '@TALK_BOARD/UPDATE_COMMENT_SUCCESS'
export const UPDATE_COMMENT_FAILURE = '@TALK_BOARD/UPDATE_COMMENT_FAILURE'

export const DELETE_COMMENT_REQUEST = '@TALK_BOARD/DELETE_COMMENT_REQUEST'
export const DELETE_COMMENT_SUCCESS = '@TALK_BOARD/DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAILURE = '@TALK_BOARD/DELETE_COMMENT_FAILURE'

export const CHANGE_FILTER_TALKBOARD = '@TALK_BOARD/CHANGE_FILTER_TALKBOARD'

export const LOAD_REPOS_ERROR = '@TALK_BOARD/LOAD_REPOS_ERROR'

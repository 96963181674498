/* eslint-disable no-unused-vars */
import { REQUEST } from '../../../store'
import {
  LOAD_LESSON_BY_ID,
  LOAD_LESSON_BY_ID_SUCCESS,

  LOAD_COURSE_DETAIL,
  LOAD_COURSE_DETAIL_SUCCESS,

  SUBMIT_LESSON_BY_ID,
  SUBMIT_LESSON_BY_ID_SUCCESS,

  LOAD_COURSE_DETAIL_UNREGISRTERED,
  LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS,

  VOTE_LIKE_UNIT,
  COUNT_VIEW_UNIT,

  LOAD_REPOS_ERROR,
  VOTE_LIKE_UNIT_SUCCESS,
  COUNT_VIEW_UNIT_SUCCESS,
  CREATE_HISTORY,
  CREATE_HISTORY_SUCCESS,
  UPLOAD_USER_PAGE_FOLDER_ID,
  UPLOAD_FILE,
  UDPATE_CREATE_LECTURE_FILE,
  RESET_CREATE_LECTURE_FILE,
  CREATE_UNIT_LESSON,
  RESET_CREATE_LESSON,
  CHANGE_ORDER_OFFSET,
  GET_DATE_COMPLETE_TIME,
  GET_DATE_COMPLETE_TIME_SUCCESS,
  LOAD_COMMENTS,
  LOAD_COMMENTS_SUCCESS,
  CREATE_UPDATE_COMMENTS,
  CREATE_UPDATE_COMMENTS_SUCCESS,
  SET_COMMENT_FOR_EDIT,
  DELETE_COMMENT,
  LIKE_COMMENT,
  DISLIKE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAILURE,
  DISLIKE_COMMENT_SUCCESS,
  DISLIKE_COMMENT_FAILURE,
  RESET_UPLOAD_USER_PAGE_FOLDER_ID
} from './constants'

export function loadCouresDetail(payload) {
  return {
    type: LOAD_COURSE_DETAIL,
    payload
  }
}

export function loadCouresDetailSuccess(data) {
  return {
    type: LOAD_COURSE_DETAIL_SUCCESS,
    data
  }
}

export function loadCouresDetailUnregistered(payload) {
  return {
    type: LOAD_COURSE_DETAIL_UNREGISRTERED,
    payload
  }
}

export function loadCouresDetailUnregisteredSuccess(data) {
  return {
    type: LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS,
    data
  }
}

export function loadLessonById(payload) {
  return {
    type: LOAD_LESSON_BY_ID,
    payload
  }
}

export function loadLessonByIdSuccess(data) {
  return {
    type: LOAD_LESSON_BY_ID_SUCCESS,
    data
  }
}

export function submitLessonById(payload) {
  return {
    type: SUBMIT_LESSON_BY_ID,
    payload
  }
}

export function submitLessonByIdSuccess() {
  return {
    type: SUBMIT_LESSON_BY_ID_SUCCESS
  }
}

export function voteLikeUnit(payload) {
  return {
    type: VOTE_LIKE_UNIT,
    payload
  }
}

export function voteLikeUnitSuccess(payload) {
  return {
    type: VOTE_LIKE_UNIT_SUCCESS,
    payload
  }
}

export function countViewUnitAction(payload) {
  return {
    type: COUNT_VIEW_UNIT,
    payload
  }
}

export function countViewUnitSuccess(data) {
  return {
    type: COUNT_VIEW_UNIT_SUCCESS,
    data
  }
}

export function repoLoadingError(error) {
  return {
    type: LOAD_REPOS_ERROR,
    error
  }
}

export function createHistory(payload) {
  return {
    type: CREATE_HISTORY,
    payload
  }
}

export function createHistorySuccess(data) {
  return {
    type: CREATE_HISTORY_SUCCESS,
    data
  }
}

export function getUserPageFolderIdAction() {
  return {
    type: REQUEST(UPLOAD_USER_PAGE_FOLDER_ID)
  }
}

export function resetUserPageFolderId() {
  return {
    type: RESET_UPLOAD_USER_PAGE_FOLDER_ID
  }
}

export function uploadFile(payload) {
  return {
    type: REQUEST(UPLOAD_FILE),
    payload
  }
}

export function getDateCompleteTime(payload) {
  return {
    type: GET_DATE_COMPLETE_TIME,
    payload
  }
}

export function updateCreateLectureFileDataAction(data) {
  return {
    type: UDPATE_CREATE_LECTURE_FILE,
    data
  }
}

export function resetCreateLectureFileDataAction() {
  return {
    type: RESET_CREATE_LECTURE_FILE
  }
}

export function createUnitLesson(payload) {
  return {
    type: REQUEST(CREATE_UNIT_LESSON),
    payload
  }
}

export function resetCreateLesson() {
  return {
    type: RESET_CREATE_LESSON
  }
}

export function changeOrderOffset(payload) {
  return {
    type: REQUEST(CHANGE_ORDER_OFFSET),
    payload
  }
}
export function getDateCompleteTimeSuccess(data) {
  return {
    type: GET_DATE_COMPLETE_TIME_SUCCESS,
    data
  }
}

export function loadComments(payload) {
  return {
    type: REQUEST(LOAD_COMMENTS),
    payload
  }
}

export function createUpdateComments(payload) {
  return {
    type: CREATE_UPDATE_COMMENTS,
    payload
  }
}

export function createUpdateCommentsSuccess(data) {
  return {
    type: CREATE_UPDATE_COMMENTS_SUCCESS,
    data
  }
}

export function setCommentForEdit(data) {
  return {
    type: SET_COMMENT_FOR_EDIT,
    data
  }
}

export function deleteComment(payload) {
  return {
    type: DELETE_COMMENT,
    payload
  }
}

export function deleteCommentSuccess(payload) {
  return {
    type: DELETE_COMMENT_SUCCESS,
    payload
  }
}

export function likeComment(payload) {
  return {
    type: LIKE_COMMENT,
    payload
  }
}

export function likeCommentSuccess(data) {
  return {
    type: LIKE_COMMENT_SUCCESS,
    data
  }
}

export function likeCommentFailure(payload) {
  return {
    type: LIKE_COMMENT_FAILURE,
    payload
  }
}

export function dislikeComment(payload) {
  return {
    type: DISLIKE_COMMENT,
    payload
  }
}

export function dislikeCommentSuccess(data) {
  return {
    type: DISLIKE_COMMENT_SUCCESS,
    data
  }
}

export function dislikeCommentFailure(payload) {
  return {
    type: DISLIKE_COMMENT_FAILURE,
    payload
  }
}

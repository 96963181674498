/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Menu, Tooltip } from 'antd'
import { TextNormal, TextPrimary } from '../../../../components'
import { StyledMenu } from '../Topic/styled'
import { ICON_DELETE, ICON_DETAIL, ICON_EDIT_TALK_BOARD } from '../../../../assets'
import { useDailyReports, useHistories } from '../../../../hooks'
import ModalDeleteFolder from '../ModalFolder/Delete'
import { ReportDetail } from './styled'
import ModalCreateFolder from '../ModalFolder/Create'
import { ROUTES_NAME } from '../../../../constants'

const Folders = ({
  item,
  index,
  dailyReportFolderId,
  handleNavigateDetailFolder,
  activeIndex,
  handleDragOver,
  handleDragLeave,
  handleDrop
}) => {
  const { t } = useTranslation()
  const history = useHistories()
  const { foldersReport, deleteDailyReportFolderAction, updateDailyReportFolderAction } = useDailyReports()
  const [isShowDeleteModalFolder, setIsShowDeleteModalFolder] = useState(false)
  const [isShowUpdateModalFolder, setIsShowUpdateModalFolder] = useState(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)

  const handleDeleteFolder = () => {
    setIsShowDeleteModalFolder(true)
  }
  const menu = useMemo(() => {
    const items = [
      {
        key: '2',
        label: (
          <StyledMenu onClick={() => setIsShowUpdateModalFolder(true)}>
            <ICON_EDIT_TALK_BOARD />
            <TextNormal className="text">{t('dailyReports.rename')}</TextNormal>
          </StyledMenu>
        ),
        isShow: !!item.id
      },
      {
        key: '3',
        label: (
          <StyledMenu onClick={handleDeleteFolder}>
            <ICON_DELETE />
            <TextNormal className="text">{t('common.delete')}</TextNormal>
          </StyledMenu>
        ),
        isShow: !!item.id
      }
    ].filter((m) => m.isShow)

    return {
      items,
      content: <Menu
        style={{ borderRadius: 4 }}
        items={items}
      />
    }
  }, [foldersReport, t])

  const submitDeleteFolder = () => {
    setIsLoadingDelete(true)
    deleteDailyReportFolderAction({
      folderId: item.id,
      callback: () => {
        setIsLoadingDelete(false)
        setIsShowDeleteModalFolder(false)
        history.push(ROUTES_NAME.DAILY_REPORTS)
      }
    })
  }
  return (
    <>
      <Tooltip title={item.folderName}>
        <div
          onClick={() => handleNavigateDetailFolder(item.id)}
          className={`folder ${(activeIndex === index)
            || (+dailyReportFolderId === item.id)
            ? 'active__folder' : ''}`}
          key={index}
          onDragOver={(event) => handleDragOver(event, index)}
          onDragLeave={handleDragLeave}
          onDrop={(e) => handleDrop(e, item.id)}
        >
          <TextPrimary fontSize="size_14" fontWeight="fw_500">
            {item.folderName}
          </TextPrimary>

          <div className="report__detail" onClick={(e) => e.stopPropagation()}>
            <ReportDetail color="#00C271" fontSize="size_14" fontWeight="fw_500">
              {item.totalReport}
            </ReportDetail>
            {menu.items.length > 0 && (
            <Dropdown
              placement="bottomRight"
              overlay={menu.content}
              trigger={['click']}
            >
              <ICON_DETAIL className="detail" color="white" />
            </Dropdown>
            )}
          </div>
        </div>
      </Tooltip>
      {isShowDeleteModalFolder && (
      <ModalDeleteFolder
        isShowDeleteModalFolder={isShowDeleteModalFolder}
        setIsShowDeleteModalFolder={setIsShowDeleteModalFolder}
        onSubmit={submitDeleteFolder}
        isLoadingDelete={isLoadingDelete}
      />
      )}
      {isShowUpdateModalFolder && (
      <ModalCreateFolder
        isVisible={isShowUpdateModalFolder}
        setIsVisible={setIsShowUpdateModalFolder}
        isUpdate
        id={item.id}
        folderName={item.folderName}
        onHandleSubmit={updateDailyReportFolderAction}
      />
      )}
    </>
  )
}

export default Folders

import styled from 'styled-components'

export const MoveFolderWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 -24px 8px -24px;
    padding: 8px 24px;
    p {
        margin-bottom: 0;
        width: calc(100% - 34px);
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-wrap: normal;
        white-space: nowrap;
    }
    &:hover {
        background-color: rgba(0, 194, 113, 0.3);
    }
`

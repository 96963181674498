/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { Form, Upload, notification } from 'antd'
import { useController, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { InboxOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { mapMimeToExt } from './constants'
import { handleUploadFileByChunk } from '../../../utils'
import ModalComponent from '../../modal-component'
import { TextNormal } from '../../text'

const WrapperFormItem = styled(Form.Item)`
  height: max-content;
  width: 100%;
  margin-bottom: 10px;

  .ant-input {
    min-height: 38px;
    border-radius: 4px;
  }

  .ant-form-item-label {
    font-size: 14px;
    overflow: unset;
    white-space: unset;
  }
`

const FormUploadDD = ({
  name,
  rules,
  defaultValue = '',
  checkExist,
  onUpload,
  setIsLeave,
  isWarningUpload,
  setIsWarningUpload,
  onCheckUpload,
  userPageFolderIdData,
  ...rest
}) => {
  const { t } = useTranslation()
  const { control, setValue, setError, clearErrors, getValues } = useFormContext()
  const { field: { onChange }, fieldState: { error } } = useController({ name, control, rules, defaultValue })
  const [modalUpload, setModalUpload] = useState(false)

  const acceptType = [
    'video/mp4',
    'video/webm',
    'video/quicktime',
    'video/avi',
    'video/x-ms-wmv',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ]

  const uploadFile = async (options) => {
    clearErrors(name)
    setIsWarningUpload(true)
    const { onSuccess, onError, file } = options
    const fileType = mapMimeToExt[file.type]
    try {
      if (!acceptType.includes(file.type)) {
        setError(name, {
          type: 'manual',
          message: t('talk_board.error_message.incorrect_file_type_upload_2', { fileName: file.name })
        })
        setIsLeave(false)
        throw new Error(t('talk_board.error_message.incorrect_file_type_upload_2'))
      } else {
        setIsLeave(true)
        if (!userPageFolderIdData) return
        // check file exist
        const { data: isExist } = await checkExist(file.name)
        if (isExist) {
          notification.error({
            message: t('common.error'),
            description: t('errors.FILE_NAME_EXIST'),
            duration: 2
          })
          setIsWarningUpload(false)
          throw new Error(t('errors.FILE_NAME_EXIST'))
        } else {
          const result = await handleUploadFileByChunk(file)
          onChange(result)
          const params = {
            filename: file.name,
            size: file.size,
            fileType,
            url: getValues(name)
          }
          onCheckUpload({
            params,
            callback: () => onSuccess('OK')
          })
        }
      }
    } catch (err) {
      setIsWarningUpload(false)
      setIsLeave(false)
      onError({ err })
    }
  }

  const handleRemove = () => {
    onChange('')
    setValue('filename', '')
    setValue('size', '')
    setValue('fileType', '')
    clearErrors(name)
  }

  const handleCancel = () => {
    setModalUpload(false)
  }

  return (
    <WrapperFormItem
      help={error?.message}
      validateStatus={error ? 'error' : ''}
    >
      <Upload.Dragger
        customRequest={onUpload || uploadFile}
        onRemove={handleRemove}
        disabled={isWarningUpload}
        {...rest}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-hint">
          {t('common.drag_upload_hint')}
        </p>
      </Upload.Dragger>
      {isWarningUpload
        && (
        <TextNormal fontSize="size_14" fontWeight="fw_400" color="text_danger">
          {t('create_lecture.validate.warning_message_upload')}
        </TextNormal>
        )}
      {modalUpload && (
        <ModalComponent
          visible={modalUpload}
          onCancel={handleCancel}
          onSubmit={handleCancel}
          title={t('common.tile_upgrade')}
          onSubmitText={t('common.offModal')}
          type="error"
          cancel={false}
        >
          <b>{t('error_message.max_file_upload_line1')}</b>
          <br />
          <b>{t('error_message.max_file_upload_line2')}</b>
        </ModalComponent>
      )}
    </WrapperFormItem>
  )
}

export default FormUploadDD

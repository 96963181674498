import * as yup from 'yup'

export default (t) => yup.object().shape({
  unitName: yup.string().trim().required(
    t('create_lecture.validate.required', {
      key: t('create_lecture.unit')
    })
  ).max(
    200,
    t('create_lecture.validate.max_length', {
      key: t('create_lecture.unit'),
      max: 200
    })
  )
})

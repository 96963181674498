/* eslint-disable no-restricted-globals */
import { useDispatch, useSelector } from 'react-redux'

import { useCallback } from 'react'
import { useInjectReducer, useInjectSaga } from '../store'
import courseSaga from '../modules/course/store/saga'
import courseReducer from '../modules/course/store/reducer'
import {
  makeSelectComments,
  makeSelectCourseDetail,
  makeSelectCourseDetailUnregistered,
  makeSelectCreateLectureFileData,
  makeSelectCreateLessonData,
  makeSelectError,
  makeSelectUploadUserPageFolderId
} from '../modules/course/store/selectors'
import {
  loadCouresDetail,
  loadCouresDetailUnregistered,
  voteLikeUnit,
  countViewUnitAction,
  getUserPageFolderIdAction,
  uploadFile,
  updateCreateLectureFileDataAction,
  resetCreateLectureFileDataAction,
  createUnitLesson,
  resetCreateLesson,
  changeOrderOffset,
  loadComments,
  createUpdateComments,
  setCommentForEdit,
  deleteComment,
  likeComment,
  dislikeComment,
  resetUserPageFolderId
} from '../modules/course/store/actions'
import { usePageNotFound } from '.'
import { useHistories } from './useHistories'

export const useCourseDetail = ({ userId, courseId }) => {
  useInjectSaga({ key: 'courseStore', saga: courseSaga })
  useInjectReducer({ key: 'courseStore', reducer: courseReducer })
  const history = useHistories()
  const flagCount = history.location?.state?.flagCount

  const dispatch = useDispatch()
  const { data: courseDetail, isLoading, isLiking, error } = useSelector(makeSelectCourseDetail())
  const { data: courseDetailUnregistered } = useSelector(makeSelectCourseDetailUnregistered())
  const errorCourseStore = useSelector(makeSelectError())
  const userPageFolderId = useSelector(makeSelectUploadUserPageFolderId())
  const createFileLectureData = useSelector(makeSelectCreateLectureFileData())
  const createLessonData = useSelector(makeSelectCreateLessonData())
  const { comments } = useSelector(makeSelectComments())

  const uploadFileAction = (payload) => dispatch(uploadFile(payload))

  const voteLikeUnitAction = (payload) => dispatch(voteLikeUnit(payload))

  usePageNotFound({ error: errorCourseStore })

  const getCourseDetail = () => {
    if (isNaN(courseId)) {
      history.push('/page-not-found')
    } else {
      dispatch(loadCouresDetail({
        userId,
        courseId,
        flagCount: flagCount || false
      }))
    }
  }

  const getCourseDetailUnregistered = () => {
    if (isNaN(courseId)) {
      history.push('/page-not-found')
    } else {
      dispatch(loadCouresDetailUnregistered({
        courseId,
        flagCount: flagCount || false
      }))
    }
  }

  const countViewUnit = (payload) => dispatch(countViewUnitAction(payload))
  const getUserPageFolderPage = () => dispatch(getUserPageFolderIdAction())
  const resetUserPageFolderIdAction = () => dispatch(resetUserPageFolderId())

  const updateCreateLectureFileData = (payload) => dispatch(updateCreateLectureFileDataAction(payload))
  const resetCreateLectureFileData = () => dispatch(resetCreateLectureFileDataAction())
  const createUnitLessonAction = (payload) => dispatch(createUnitLesson(payload))
  const resetCreateLessonAction = () => dispatch(resetCreateLesson())
  const changeOrderOffsetAction = (payload) => dispatch(changeOrderOffset(payload))
  const loadCommentsAction = (payload) => dispatch(loadComments(payload))
  const createUpdateCommentsAction = (payload) => dispatch(createUpdateComments(payload))
  const setCommentForEditAction = useCallback((payload) => dispatch(setCommentForEdit(payload)), [])
  const deleteCommentAction = useCallback((payload) => dispatch(deleteComment(payload)), [])
  const likeCommentAction = useCallback((payload) => dispatch(likeComment(payload)), [])
  const dislikeCommentAction = useCallback((payload) => dispatch(dislikeComment(payload)), [])

  return {
    courseDetail,
    loadCouresDetail: getCourseDetail,
    isLoading,
    isLiking,
    error,
    errorCourseStore,
    courseDetailUnregistered,
    userPageFolderId,
    createFileLectureData,
    createLessonData,
    comments,
    getCourseDetailUnregistered,
    voteLikeUnitAction,
    countViewUnit,
    getUserPageFolderPage,
    resetUserPageFolderIdAction,
    uploadFileAction,
    updateCreateLectureFileData,
    resetCreateLectureFileData,
    createUnitLessonAction,
    resetCreateLessonAction,
    changeOrderOffsetAction,
    loadCommentsAction,
    createUpdateCommentsAction,
    setCommentForEditAction,
    likeCommentAction,
    dislikeCommentAction,
    deleteCommentAction
  }
}

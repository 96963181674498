export const LOAD_STUDYING_INDIVIDUAL = '@APP/LOAD_COURSES_STUDYING_INDIVIDUAL'
export const LOAD_STUDYING_INDIVIDUAL_SUCCESS = '@APP/LOAD_COURSES_STUDYING_INDIVIDUAL_SUCCESS'
export const LOAD_COSTS_INDIVIDUAL = '@APP/LOAD_COURSES_COSTS_INDIVIDUAL'
export const LOAD_COSTS_INDIVIDUAL_SUCCESS = '@APP/LOAD_COURSES_COSTS_INDIVIDUAL_SUCCESS'
export const LOAD_STUDYING_COMPANY = '@APP/LOAD_COURSES_STUDYING_COMPANY'
export const LOAD_STUDYING_COMPANY_SUCCESS = '@APP/LOAD_COURSES_STUDYING_COMPANY_SUCCESS'
export const LOAD_STUDYING_NISSOKEN = '@APP/LOAD_COURSES_STUDYING_NISSOKEN'
export const LOAD_STUDYING_NISSOKEN_SUCCESS = '@APP/LOAD_COURSES_STUDYING_NISSOKEN_SUCCESS'
export const LOAD_REQUIRED_COMPANY = '@APP/LOAD_COURSES_REQUIRED_COMPANY'
export const LOAD_REQUIRED_COMPANY_SUCCESS = '@APP/LOAD_COURSES_REQUIRED_COMPANY_SUCCESS'
export const LOAD_REPOS_ERROR = '@APP/LOAD_REPOS_ERROR'
export const LOAD_COURSE_PROGRESS = '@APP/LOAD_COURSE_PROGRESS'
export const LOAD_COURSE_PROGRESS_SUCCESS = '@APP/LOAD_COURSE_PROGRESS_SUCCESS'
export const HIDE_COURSE = '@APP/HIDE_COURSE'
export const HIDE_COURSE_SUCCESS = '@APP/HIDE_COURSE_SUCCESS'
export const VOTE_LIKE_COURSE = '@APP/VOTE_LIKE_COURSE'
export const VOTE_LIKE_COURSE_SUCCESS = '@APP/VOTE_LIKE_COURSE_SUCCESS'
export const RESET_FILTER_MY_PAGE = '@APP/RESET_FILTER_MY_PAGE'
export const UPDATE_ORDER_COURSES = '@APP/UPDATE_ORDER_COURSES'
export const UPDATE_BOOKMARK_COURSE_DISPLAY_TYPE = '@APP/UPDATE_BOOKMARK_COURSE_DISPLAY_TYPE'

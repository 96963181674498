export const VERIFY_COMPANY = '@REGISTER_COMPANY/VERIFY_COMPANY'
export const VERIFY_COMPANY_SUCCESS = '@REGISTER_COMPANY/VERIFY_COMPANY_SUCCESS'
export const VERIFY_COMPANY_ERROR = '@REGISTER_COMPANY/VERIFY_COMPANY_ERROR'

export const REGISTER_COMPANY = '@REGISTER_COMPANY/REGISTER_COMPANY'
export const REGISTER_COMPANY_SUCCESS = '@REGISTER_COMPANY/REGISTER_COMPANY_SUCCESS'
export const REGISTER_COMPANY_ERROR = '@REGISTER_COMPANY/REGISTER_COMPANY_ERROR'

export const GET_LIST_PLAN_PACKAGE = '@REGISTER_COMPANY/GET_LIST_PLAN_PACKAGE'
export const GET_LIST_PLAN_PACKAGE_SUCCESS = '@REGISTER_COMPANY/GET_LIST_PLAN_PACKAGE_SUCCESS'
export const GET_LIST_PLAN_PACKAGE_ERROR = '@REGISTER_COMPANY/GET_LIST_PLAN_PACKAGE_ERROR'

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import YouTube from 'react-youtube'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { YouTubeGetID } from '../../utils/utils'
import { saveLastViewedVideoAPI } from '../../apis'
import { VIDEO_WATCH_TIME, YOUTUBE_STATE_CHANGE, LESSON_STATUS_COMPLETE } from '../../constants'
import { useProfile, useUnitLesson } from '../../hooks'
import { STORAGE, floorNumber, getLocalStorage, roundNumber, setLocalStorage } from '../../utils'
import Modal from '../modal'

const Wrapper = styled.div`
  position: relative;
  height: max-content;
  iframe {
    display: block;
    width: 100%;
    height: calc(100vh - 170px);
  }
  .overlay__progress__bar {
    position: absolute;
    bottom: 30px;
    width: 100%;
    height: 30px;
  }
`

export const getLessonsViewedWithoutCompleted = () => JSON.parse(getLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED)) || []

const Youtube = ({ src, courseId, lessonId, historyId, lessonDetail, countViewUnit, submitLesson, typeUnit, units }) => {
  // Use hooks
  const { t } = useTranslation()
  const videoId = YouTubeGetID(src)
  // End use hooks

  // Use state
  const [isViewed, setIsViewed] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentVideoPlayTime, setCurrentVideoPlayTime] = useState({
    previous: 0,
    current: 0
  })
  const [durationVideoPlayTime, setDurationVideoPlayTime] = useState(0)
  const [hasWarningBuffering, setHasWarningBuffering] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)
  const [isBufferedForwardVideoCompleted, setIsBufferedForwardVideoCompleted] = useState(false)
  const [newHistoryId, setNewHistoryId] = useState(historyId)
  const [firstPress, setFirstPress] = useState(false)

  const lessonsViewedWithoutCompleted = getLessonsViewedWithoutCompleted()
  const indexOfLessonViewedWithoutCompleted = [...getLessonsViewedWithoutCompleted()].findIndex((lesson) => lesson.lessonId === lessonId)
  let newLessonsViewedWithoutCompleted = [...lessonsViewedWithoutCompleted]
  let isCountFromStorage = newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted]?.isCount || false
  let isSeekFromStorage = newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted]?.isSeek || false

  const { profile } = useProfile()
  const { createHistoryAction } = useUnitLesson({
    userId: profile.userId,
    courseId,
    lessonId,
    isCreateHistory: true
  })

  const dateCompleteTime = JSON.parse(getLocalStorage(STORAGE.DATE_COMPLETE_TIME))
  // End use state

  const youtubeRef = useRef()

  const isCompletedInDatabase = useMemo(() => units && units.filter((unit) => unit.unitId.toString() === lessonId.toString())[0].complete === LESSON_STATUS_COMPLETE.COMPLETED, [units, lessonId])

  useEffect(() => {
    if (isCountFromStorage) {
      setIsViewed(isCountFromStorage)
    }
  }, [isCountFromStorage])

  const onSaveLastViewedVideoAPI = (time) => {
    saveLastViewedVideoAPI({
      courseId,
      lessonId,
      data: { lastViewedAt: time }
    })
  }

  const onEnd = async () => {
    // Set last viewed to zero if the user finishes watching the video.
    await onSaveLastViewedVideoAPI(0)
    setIsBufferedForwardVideoCompleted(false)
    setNewHistoryId(undefined)
    setCurrentVideoPlayTime({ previous: 0, current: 0 })
    setIsPlaying(false)
    setIsViewed(false)
    setIsCompleted(false)
    setFirstPress(false)
  }

  const saveIsCountStorage = (value) => {
    if (indexOfLessonViewedWithoutCompleted !== -1) {
      newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].isCount = value
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
    }
  }

  const saveIsSeekStorage = (value) => {
    if (indexOfLessonViewedWithoutCompleted !== -1) {
      newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].isSeek = value
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
    }
  }

  const handleOnStateChange = (event) => {
    const { target, data } = event
    const currentTime = target.getCurrentTime()
    const isBuffering = floorNumber(currentTime) > floorNumber(currentVideoPlayTime.current + 1)

    if ((data === YOUTUBE_STATE_CHANGE.PLAYING || data === YOUTUBE_STATE_CHANGE.BUFFERING) && isBuffering) {
      if (!isCompletedInDatabase) {
        target.pauseVideo()
        setHasWarningBuffering(true)
      } else if (!isCompleted) {
        setIsBufferedForwardVideoCompleted(true)
        saveIsSeekStorage(true)
      }
    }

    if (data === YOUTUBE_STATE_CHANGE.PLAYING) {
      if (isPlaying === false) {
        setIsPlaying(true)
      }
      if (currentTime < currentVideoPlayTime.current) {
        setCurrentVideoPlayTime((prev) => ({ ...prev, previous: currentTime }))
      }
    }

    if (data === YOUTUBE_STATE_CHANGE.PAUSED && !isBuffering) {
      setIsPlaying(false)
      onSaveLastViewedVideoAPI(((durationVideoPlayTime - currentTime) <= dateCompleteTime || currentTime === durationVideoPlayTime) ? 0 : currentTime)
    }
  }

  const handleOnPlay = () => {
    if (!firstPress) {
      if (indexOfLessonViewedWithoutCompleted !== -1) {
        setNewHistoryId(newLessonsViewedWithoutCompleted[indexOfLessonViewedWithoutCompleted].historyId)
      } else {
        createHistoryAction({
          courseId,
          lessonId,
          typeUnit,
          callback: {
            done: (data) => {
              setNewHistoryId(data)
              newLessonsViewedWithoutCompleted.push({ lessonId, historyId: data, isSeek: false, isCount: false })
              setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(newLessonsViewedWithoutCompleted))
              setIsPlaying(true)
            }
          } })
      }
      setFirstPress(true)
    }
  }

  const handleOnReady = (event) => {
    const { target } = event
    const durationTime = target.getDuration()
    setDurationVideoPlayTime(durationTime)
    if (lessonDetail.lastViewedAt) {
      setCurrentVideoPlayTime({
        previous: lessonDetail.lastViewedAt,
        current: lessonDetail.lastViewedAt
      })
    }
  }

  const handleSeekToCurrentTime = useCallback(() => {
    const isOutdateCurrentTime = roundNumber(currentVideoPlayTime.current) > roundNumber(currentVideoPlayTime.previous)
    if (isOutdateCurrentTime) {
      youtubeRef.current.internalPlayer.seekTo(currentVideoPlayTime.previous)
      onSaveLastViewedVideoAPI(currentVideoPlayTime.previous)
    } else {
      youtubeRef.current.internalPlayer.seekTo(currentVideoPlayTime.current)
      onSaveLastViewedVideoAPI(currentVideoPlayTime.current)
    }
    youtubeRef.current.internalPlayer.playVideo()
  }, [currentVideoPlayTime])

  useEffect(() => {
    let timerId = null
    if (isPlaying) {
      timerId = setInterval(async () => {
        const currentTimeYoutube = await youtubeRef.current.internalPlayer.getCurrentTime() // This method return a promise
        setCurrentVideoPlayTime((prev) => {
          if (roundNumber(prev.previous) === roundNumber(prev.current)) {
            return { previous: currentTimeYoutube, current: currentTimeYoutube }
          }
          return { ...prev, previous: currentTimeYoutube }
        })
      }, 500)
    } else {
      clearInterval(timerId)
    }
    return () => clearInterval(timerId)
  }, [isPlaying])

  const onSubmitLesson = () => {
    submitLesson({ queryParams: { historyId: newHistoryId } })
    setIsCompleted(true)
    if (indexOfLessonViewedWithoutCompleted !== -1) {
      lessonsViewedWithoutCompleted.splice(-indexOfLessonViewedWithoutCompleted, 1)
      setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(lessonsViewedWithoutCompleted))
    }
    saveLastViewedVideoAPI({
      courseId,
      lessonId,
      data: { lastViewedAt: 0 }
    })
  }

  useEffect(() => {
    if (isPlaying) {
      const currentTime = currentVideoPlayTime.current
      const duration = durationVideoPlayTime

      if ((currentTime / duration) >= VIDEO_WATCH_TIME && !isViewed && !isCountFromStorage) {
        countViewUnit({
          courseId,
          lessonId,
          typeUnit,
          isVideo: true
        })
        if (indexOfLessonViewedWithoutCompleted !== -1) {
          saveIsCountStorage(true)
        }
        setIsViewed(true)
      }

      if (isViewed && !isBufferedForwardVideoCompleted && (duration - currentTime) <= dateCompleteTime && !isCompleted && !isSeekFromStorage) {
        onSubmitLesson()
      }

      if (duration - Math.floor(currentTime) <= dateCompleteTime && isSeekFromStorage) {
        if (indexOfLessonViewedWithoutCompleted !== -1) {
          lessonsViewedWithoutCompleted.splice(-indexOfLessonViewedWithoutCompleted, 1)
          setLocalStorage(STORAGE.LESSONS_VIEWED_WITHOUT_COMPLETED, JSON.stringify(lessonsViewedWithoutCompleted))
          saveLastViewedVideoAPI({
            courseId,
            lessonId,
            data: { lastViewedAt: 0 }
          })
          setIsCompleted(true)
        }
      }
    }
  }, [currentVideoPlayTime.current, currentVideoPlayTime.previous, durationVideoPlayTime, isCompleted, isBufferedForwardVideoCompleted])

  return (
    <Wrapper className="wrapper">
      <YouTube
        ref={youtubeRef}
        videoId={videoId}
        onEnd={onEnd}
        onPlay={handleOnPlay}
        onStateChange={handleOnStateChange}
        onReady={handleOnReady}
        opts={{
          // https://developers.google.com/youtube/player_parameters
          playerVars: {
            start: lessonDetail.lastViewedAt || 0,
            // autoplay: autoPlay ? 1 : 0,
            fs: 0, // Hide button fullscreen
            modestbranding: 1, // Remove youtube logo at right bottom
            cc_load_policy: 1, // Force Closed Captions
            iv_load_policy: 3, // Turn off Annotations
            rel: 1, // Turn on Related Videos
            disablekb: 1 // Disabled keyboard
          }
        }}
      />
      <Modal
        isModalVisible={hasWarningBuffering}
        onOk={handleSeekToCurrentTime}
        isCancel={false}
        setIsModalVisible={setHasWarningBuffering}
        description={t('lesson.not_allow_buffering')}
        okText={t('common.yes')}
        borderRadiusButton={6}
      />
    </Wrapper>

  )
}

Youtube.propTypes = {
  src: PropTypes.string,
  submitLesson: PropTypes.func
}

export default Youtube

export const mapMimeToExt = {
  'video/x-msvideo': 'avi',
  'video/avi': 'avi',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpeg',
  'video/ogg': 'ogv',
  'video/mp2t': 'ts',
  'video/webm': 'webm',
  'video/3gpp': '3gp',
  'video/3gpp2': '3g2',
  'video/quicktime': 'MOV',
  'video/x-flv': 'flv',
  'video/x-ms-wmv': 'wmv',
  'application/pdf': 'pdf',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx'
}

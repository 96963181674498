export const LOAD_SURVEY = '@SURVEY/LOAD_SURVEY'
export const LOAD_SURVEY_SUCCESS = '@SURVEY/LOAD_SURVEY_SUCCESS'
export const LOAD_SURVEY_ERROR = '@SURVEY/LOAD_LIST_UNIT_QUESTION_ERROR'

export const LOAD_SURVEY_RESULT = '@SURVEY/LOAD_SURVEY_RESULT'
export const LOAD_SURVEY_RESULT_SUCCESS = '@SURVEY/LOAD_SURVEY_RESULT_SUCCESS'
export const LOAD_SURVEY_RESULT_ERROR = '@SURVEY/LOAD_SURVEY_RESULT_ERROR'

export const UPDATE_ANSWER_SURVEY = '@SURVEY/UPDATE_ANSWER_SURVEY'

export const SUBMIT_SURVEY = '@SURVEY/SUBMIT_SURVEY'
export const SUBMIT_SURVEY_SUCCESS = '@SURVEY/SUBMIT_SURVEY_SUCCESS'
export const SUBMIT_SURVEY_ERROR = '@SURVEY/SUBMIT_SURVEY_ERROR'

export const LOAD_REPOS_ERROR = '@SURVEY/LOAD_REPOS_ERROR'

/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Divider, Row, Spin, Select } from 'antd'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { SearchOutlined } from '@ant-design/icons'
import Comments from '../comments'
import { ClickAble, FormInput, FormTextArea, SubmitButton, TextNormal, TextPrimary } from '../../../../components'
import { DEFAULT_PAG, LESSON_DETAIL_TYPE, TALK_BOARD_SORT } from '../../../../constants'
import schema from './schema'
import { useCourseDetail, useProfile } from '../../../../hooks'
import { STORAGE, getLocalStorage, hasValuesInObject } from '../../../../utils'
import { StyledWrapper } from './styled'
import Image from '../../../../components/image'
import { SHOW_LESS_ICON_V2, SHOW_MORE_ICON_V2 } from '../../../../assets'
import { StyledSearchWrapper } from '../../../dailyReport/DailyReport/styled'

const DEFAULT_VALUE = { content: '' }
const DEFAULT_VALUE_SEARCH = { textSearch: '' }

const CommentForm = ({ unitId, courseId, type, isList = false }) => {
  // Use hooks
  const { t } = useTranslation()
  const { profile } = useProfile()
  const [isToggleComment, setIsToggleComment] = useState(false)
  const {
    loadCommentsAction,
    comments: { commentForEdit, filter, isSubmitting, data, isLoading: isLoadingComments, pagination, isLiking },
    createUpdateCommentsAction,
    setCommentForEditAction
  } = useCourseDetail({
    userId: profile.userId
  })

  const language = getLocalStorage(STORAGE.LANGUAGE)
  const DEF_CHARACTER = language === 'jp' ? 140 : 280

  const form = useForm({
    defaultValues: DEFAULT_VALUE,
    resolver: yupResolver(schema(t, DEF_CHARACTER))
  })

  const formSearch = useForm({
    defaultValues: DEFAULT_VALUE_SEARCH
  })
  const { handleSubmit, formState: { errors }, reset, clearErrors, setValue, getValues } = form
  const { handleSubmit: handleSubmitSearch } = formSearch
  const formValues = getValues()
  const commentRef = useRef()
  const [isFocusingSort, setIsFocusingSort] = useState(false)

  // End use hooks

  // Use states
  // End use states

  const hasRequestEditComment = useMemo(() => Object.keys(commentForEdit).length > 0, [commentForEdit])

  const onSearch = (formData) => {
    const { textSearch } = formData
    loadCommentsAction({ data: { ...filter, unitId, courseId, textSearch: textSearch.trim(), page: DEFAULT_PAG.page } })
  }

  const handleSelectOrder = (value) => {
    loadCommentsAction({ data: { ...filter, unitId, courseId, sortType: value } })
  }

  const toggleComment = () => {
    setIsToggleComment((prev) => !prev)
  }

  const onSubmit = async (formData) => {
    const { content } = formData
    if (hasRequestEditComment) {
      const dataComment = {
        comment: content,
        unitId,
        courseId,
        id: commentForEdit.id
      }
      createUpdateCommentsAction({ data: dataComment, filter })
      setCommentForEditAction({})
    } else {
      const dataComment = {
        comment: content,
        unitId,
        courseId
      }
      createUpdateCommentsAction({ data: dataComment, filter })
    }
  }

  const handleChangePag = useCallback((currentPage, pageSize) => {
    loadCommentsAction({ data: { ...filter, unitId, courseId, page: currentPage, limit: pageSize } })
  }, [filter])

  useEffect(() => {
    if (errors) {
      clearErrors()
    }
  }, [t])

  useEffect(() => {
    if (!isSubmitting && hasValuesInObject(formValues)) {
      reset(DEFAULT_VALUE)
    }
  }, [isSubmitting])

  useEffect(() => {
    if (unitId || courseId) {
      loadCommentsAction({ data: {
        unitId,
        courseId
      },
      filter })
    }
  }, [unitId, courseId])

  useEffect(() => {
    if (hasRequestEditComment) {
      commentRef.current.focus()
      setValue('content', commentForEdit.comment)
      if (errors) {
        clearErrors()
      }
    }
  }, [hasRequestEditComment, commentForEdit])

  useEffect(() => {
    if (type !== LESSON_DETAIL_TYPE.PDF) {
      setIsToggleComment(true)
    }
  }, [type])

  return (
    <StyledWrapper isList={isList}>
      <div className={`${type === LESSON_DETAIL_TYPE.PDF ? 'comment-container-pdf' : ''} comment-container`}>
        {type === LESSON_DETAIL_TYPE.PDF ? (
          <ClickAble onClick={toggleComment} className="header-comment">
            <TextPrimary color="text_active_green" className="lesson-name" textAlign="center" fontWeight="fw_400" fontSize="size_14">
              {isToggleComment ? t('course_detail.hide_comment') : t('course_detail.show_comment')}
            </TextPrimary>
            <i className={`${isToggleComment ? 'down' : 'up'} arrow`} />
          </ClickAble>
        ) : null}
        { isToggleComment ? (
          <div className="content-comment">
            <FormProvider {...form}>
              <TextPrimary className="title" color="text_active_green" fontWeight="fw_500">{t('dailyReports.enter_comment')}</TextPrimary>
              <FormTextArea
                ref={commentRef}
                t={t}
                name="content"
                rows={6}
                total={DEF_CHARACTER}
                id="formEditComment"
                placeholder={t('course_detail.input_comment')}
              />
              <Row style={{ marginTop: '35px', justifyContent: 'flex-end' }}>
                <Col>
                  <div className="button__box">
                    <SubmitButton
                      title={<span>{t(hasRequestEditComment ? 'common.save' : 'talk_board.post')}</span>}
                      borderRadius={6}
                      onClick={handleSubmit(onSubmit)}
                      disabled={Object.keys(errors).length}
                      loading={isSubmitting}
                    />
                  </div>
                </Col>
              </Row>
            </FormProvider>
            <div>
              <div className="comment__header">
                <Select
                  suffixIcon={<Image src={isFocusingSort ? SHOW_LESS_ICON_V2 : SHOW_MORE_ICON_V2} />}
                  onBlur={() => setIsFocusingSort(false)}
                  onClick={() => setIsFocusingSort((prev) => !prev)}
                  onChange={handleSelectOrder}
                  value={filter.sortType}
                >
                  {TALK_BOARD_SORT.map((option) => (
                    <Select.Option
                      key={option.key}
                      value={option.value}
                    >
                      {t(`talk_board.${option.label}`)}
                    </Select.Option>
                  ))}
                </Select>
                <StyledSearchWrapper style={{ width: '50%' }}>
                  <FormProvider {...formSearch}>
                    <form onSubmit={handleSubmitSearch(onSearch)}>
                      <FormInput
                        t={t}
                        prefix={<SearchOutlined onClick={handleSubmit(onSearch)} />}
                        name="textSearch"
                        placeholder={t('talk_board.search_comment_placeholder')}
                      />
                    </form>
                  </FormProvider>
                </StyledSearchWrapper>
              </div>
              <Spin spinning={isLoadingComments}>
                <Comments
                  comments={data}
                  pagination={pagination}
                  handleChangePag={handleChangePag}
                />
              </Spin>
            </div>
          </div>
        ) : null}
      </div>
    </StyledWrapper>

  )
}

export default memo(CommentForm)

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams, Prompt } from 'react-router-dom'

import { Popconfirm, Spin } from 'antd'
import { CloseOutlined, EditOutlined } from '@ant-design/icons'
import CreateTestShema from './schema'
import FormUploadDD from '../../../../components/form/FormUploadDD'
import { checkExistFile } from '../../../../apis'
import { ModalWrapper, TextWrapper } from './styled'
import { useCourseDetail, useProfile } from '../../../../hooks'

const UploadFileModal = ({ onClose, visible, setIsLeave, isShowLeave, setIsShowModalReport, getUserPageFolderPage }) => {
  const { t } = useTranslation()
  const { courseId } = useParams()
  const { profile } = useProfile()

  const [isWarningUpload, setIsWarningUpload] = useState(false)

  const { userPageFolderId, uploadFileAction, updateCreateLectureFileData } = useCourseDetail({
    userId: profile.userId,
    courseId
  })

  const { isLoading, data: userPageFolderIdData } = userPageFolderId

  const form = useForm({
    resolver: yupResolver(CreateTestShema(t)),
    defaultValues: {
      url: ''
    }
  })

  const handleCheckExistFile = async (fileName) => checkExistFile({ folderId: userPageFolderIdData, fileName })

  useEffect(() => {
    getUserPageFolderPage()
  }, [])

  const onCheckUpload = ({ params, callback }) => {
    const handleUploadSuccess = () => {
      onClose(false)
      setIsLeave(false)
      updateCreateLectureFileData(params)
      setIsShowModalReport(true)
      setIsWarningUpload(false)
      callback()
    }

    const handleUploadFailure = () => {
      setIsWarningUpload(false)
    }

    uploadFileAction({
      folderId: userPageFolderId.data,
      params: {
        ...params,
        folderParent: 'ユーザー画面からアップするファイル'
      },
      onSuccess: handleUploadSuccess,
      onFailure: handleUploadFailure
    })
  }

  const renderClose = () => {
    if (isWarningUpload) {
      return (
        <Popconfirm
          onConfirm={() => onClose(false)}
          cancelText={t('common.cancel')}
          title={t('course_detail.upload_file.leave_confirm')}
        >
          <div className="ant-modal-close" type="button">
            <div className="ant-modal-close-x">
              <div className="anticon anticon-close ant-modal-close-icon">
                <CloseOutlined />
              </div>
            </div>
          </div>
        </Popconfirm>
      )
    }
    return (
      <button className="ant-modal-close" onClick={() => onClose(false)} type="button">
        <div className="ant-modal-close-x">
          <div className="anticon anticon-close ant-modal-close-icon">
            <CloseOutlined />
          </div>
        </div>
      </button>
    )
  }

  return (
    <>
      <FormProvider {...form}>
        <ModalWrapper
          visible={visible}
          isNotFooterButton
          width="60vw"
          footer={null}
        >

          <div className="ant-modal-header">
            {renderClose()}
            <div
              style={{
                height: 36,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                padding: 0,
                fontSize: 24
              }}
            >
              <EditOutlined />
              <TextWrapper fontWeight="fw_700" fontSize="size_20">
                {t('course_detail.upload_file.title')}
              </TextWrapper>
            </div>
          </div>
          <p style={{
            margin: 0
          }}
          >
            {t('course_detail.upload_file_note')}
            <br />
            <TextWrapper fontWeight="fw_400" color="red">
              {t('course_detail.upload_file_warn')}
            </TextWrapper>
          </p>
          <h4>{t('course_detail.upload_file.file')}</h4>

          <Spin spinning={isLoading}>
            <FormUploadDD
              name="url"
              checkExist={handleCheckExistFile}
              setIsLeave={setIsLeave}
              setIsWarningUpload={setIsWarningUpload}
              isWarningUpload={isWarningUpload}
              onCheckUpload={onCheckUpload}
              userPageFolderIdData={userPageFolderIdData}
            />
          </Spin>
          <Prompt
            when={isShowLeave}
            message={t('course_detail.upload_file.leave_confirm')}
          />

        </ModalWrapper>
      </FormProvider>
    </>
  )
}

export default UploadFileModal

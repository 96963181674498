import styled from 'styled-components'
import { MEDIA_WIDTHS } from '../../../themes'

export const Label = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: .5rem;

	width: ${({ width }) => `${width}%`};
	min-height: 100%;
	padding: 16px;

	@media screen and (max-width: ${MEDIA_WIDTHS.upToExtraSmall}px) {
		width: 100%;
		padding-bottom: 5px;
	}
	p {
		flex: 1;
	}
`

export const Content = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
`

export const Description = styled.span`
	color: ${({ theme }) => theme.white};
	background: ${({ description }) => (description === 'Optional' ? '#777777' : '#6C5FD2')};
	padding: 3px 8px;
	border-radius: 1rem;
	font-weight: 600;
	font-size: 10px;
	margin-left: 5px;
`
export const TextWrapper = styled.p`
color: ${({ color, theme }) => theme[color]};
padding: 0;
margin: 0;
font-size: ${({ fontSize, theme }) => theme[fontSize] || '16px'};
font-weight: ${({ fontWeight, theme }) => theme[fontWeight] || '400'};
`

export const LOAD_LESSON_BY_ID = '@COURSE/LOAD_LESSON_BY_ID'
export const LOAD_LESSON_BY_ID_SUCCESS = '@COURSE/LOAD_LESSON_BY_ID_SUCCESS'
export const LOAD_LESSON_BY_ID_ERROR = '@COURSE/LOAD_LESSON_BY_ID_ERROR'

export const SUBMIT_LESSON_BY_ID = '@COURSE/SUBMIT_LESSON_BY_ID'
export const SUBMIT_LESSON_BY_ID_SUCCESS = '@COURSE/SUBMIT_LESSON_BY_ID_SUCCESS'
export const SUBMIT_LESSON_BY_ID_ERROR = '@COURSE/SUBMIT_LESSON_BY_ID_ERROR'

export const LOAD_COURSE_DETAIL = '@COURSE/LOAD_COURSE_DETAIL'
export const LOAD_COURSE_DETAIL_SUCCESS = '@COURSE/LOAD_COURSE_DETAIL_SUCCESS'
export const LOAD_COURSE_DETAIL_ERROR = '@COURSE/LOAD_COURSE_DETAIL_ERROR'

export const LOAD_COURSE_DETAIL_UNREGISRTERED = '@COURSE/LOAD_COURSE_DETAIL_UNREGISRTERED'
export const LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS = '@COURSE/LOAD_COURSE_DETAIL_UNREGISRTERED_SUCCESS'
export const LOAD_COURSE_DETAIL_UNREGISRTERED_ERROR = '@COURSE/LOAD_COURSE_DETAIL_UNREGISRTERED_ERROR'

export const VOTE_LIKE_UNIT = '@COURSE/VOTE_LIKE_UNIT'
export const VOTE_LIKE_UNIT_SUCCESS = '@COURSE/VOTE_LIKE_UNIT_SUCCESS'

export const COUNT_VIEW_UNIT = '@COURSE/COUNT_VIEW_UNIT'
export const COUNT_VIEW_UNIT_SUCCESS = '@COURSE/COUNT_VIEW_UNIT_SUCCESS'

export const LOAD_REPOS_ERROR = '@COURSE/LOAD_REPOS_ERROR'
export const CREATE_HISTORY = '@COURSE/CREATE_HISTORY'
export const CREATE_HISTORY_SUCCESS = '@COURSE/CREATE_HISTORY_SUCCESS'

export const UPLOAD_USER_PAGE_FOLDER_ID = '@COURSE/UPLOAD_USER_PAGE_FOLDER_ID'
export const RESET_UPLOAD_USER_PAGE_FOLDER_ID = '@COURSE/RESET_UPLOAD_USER_PAGE_FOLDER_ID'
export const UPLOAD_FILE = '@COURSE/UPLOAD_FILE'
export const UDPATE_CREATE_LECTURE_FILE = '@COURSE/UDPATE_CREATE_LECTURE_FILE'
export const RESET_CREATE_LECTURE_FILE = '@COURSE/RESET_CREATE_LECTURE_FILE'
export const CREATE_UNIT_LESSON = '@COURSE/CREATE_UNIT_LESSON'
export const RESET_CREATE_LESSON = '@COURSE/RESET_CREATE_LESSON'
export const CHANGE_ORDER_OFFSET = '@COURSE/CHANGE_ORDER_OFFSET'
export const GET_DATE_COMPLETE_TIME = '@COURSE/GET_DATE_COMPLETE_TIME'
export const GET_DATE_COMPLETE_TIME_SUCCESS = '@COURSE/GET_DATE_COMPLETE_TIME_SUCCESS'
export const LOAD_COMMENTS = '@COURSE/LOAD_COMMENTS'
export const LOAD_COMMENTS_SUCCESS = '@COURSE/LOAD_COMMENTS_SUCCESS'
export const CREATE_UPDATE_COMMENTS = '@COURSE/CREATE_UPDATE_COMMENTS'
export const CREATE_UPDATE_COMMENTS_SUCCESS = '@COURSE/CREATE_UPDATE_COMMENTS_SUCCESS'
export const SET_COMMENT_FOR_EDIT = '@COURSE/SET_COMMENT_FOR_EDIT'
export const DELETE_COMMENT = '@COURSE/DELETE_COMMENT'
export const DELETE_COMMENT_SUCCESS = '@COURSE/DELETE_COMMENT_SUCCESS'
export const DELETE_COMMENT_FAILURE = '@COURSE/DELETE_COMMENT_FAILURE'
export const LIKE_COMMENT = '@COURSE/LIKE_COMMENT'
export const LIKE_COMMENT_SUCCESS = '@COURSE/LIKE_COMMENT_SUCCESS'
export const LIKE_COMMENT_FAILURE = '@COURSE/LIKE_COMMENT_FAILURE'
export const DISLIKE_COMMENT = '@COURSE/DISLIKE_COMMENT'
export const DISLIKE_COMMENT_SUCCESS = '@COURSE/DISLIKE_COMMENT_SUCCESS'
export const DISLIKE_COMMENT_FAILURE = '@COURSE/DISLIKE_COMMENT_FAILURE'

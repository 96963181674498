import styled from 'styled-components'

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1096px;
  color: ${({ theme }) => theme.talk_primary};
  padding-top: 10px;

  .header-block {
    padding: 0 !important;
  }

  .reports-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.grey_blur};;
    border-radius: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    .ant-pagination {
      align-self: center
    }
  }
  .folders-wrapper {
    position: sticky;
    top: 10px;
    border-radius: 8px;
    height: fit-content;
    border: 1px solid ${({ theme }) => theme.grey_blur};
    padding: 10px 0;
    .reports_btn {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .folders {
      max-height: 60vh;
      overflow-x: auto;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      padding: 0 10px;
    }

    .folder {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #F1F1F1;
      border-radius: 4px;
      padding: 11px 20px;
      height: 44px;
      cursor: pointer;
      gap: 10px;
      p {
          text-overflow: ellipsis;
          overflow: hidden; 
          white-space: nowrap;
          color: #1A1A1A;
      }
      &:hover {
        background-color: ${({ theme }) => theme.green};
        p {
          color: white !important;
        }

        svg {
          path {
            fill: white;
          }
        }
      }
    }

    .report__detail {
      display: flex;
      position: relative;
      .detail {
        position: absolute;
        right: -10px;
      }
    }

    .active__folder {
      background-color: ${({ theme }) => theme.green};
      p {
        color: white !important;
      }

      svg {
        path {
          fill: white;
        }
      }
    }
    .btn__addnew {
      margin: 10px 10px 0 10px;
      width: calc(100% - 20px);
      background-color: white;
      border-radius: 4px;
      border: 1px solid ${({ theme }) => theme.success};
      p {
        color: ${({ theme }) => theme.green};
      }
      &:hover {
        opacity: 0.8;
        background-color: ${({ theme }) => theme.success};
        p {
          color: white;
        }
      }
      &:active,
      &:focus {
        border: 1px solid ${({ theme }) => theme.success};
      }
    }
  }
  .list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 64px - 382px);
  p {
    text-align: center;
  }

  .ant-pagination {
    .anticon {
      color: ${({ theme }) => theme.talk_primary};
    }
    .ant-select-selector, .ant-pagination-jump-next {
      &:hover, &:focus, &:active {
        border-color: ${({ theme }) => theme.talk_primary};
      }
    }
    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: ${({ theme }) => theme.talk_primary};
      box-shadow: none;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${({ theme }) => theme.talk_primary};
      box-shadow: none;
    }
  }
}
`
